import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  useBreakpointValue,
  Text,
  Image,
  IconButton,
  Flex
} from '@chakra-ui/react';
import { useHistory } from "react-router-dom";
import * as FormFields from './FormFields';
import { DeleteIcon } from '@chakra-ui/icons';
import { ensureConvertersInitialized, handleTranslate } from './utils/TranslationHelper';
import { formatTranslationString } from './utils/formatTranslationString';
import DataTransformer from './utils/DataTransformer';
import TableOperations from './utils/TableOperations';

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import MultiImageField from './FormFields/MultiImageField';
import { Tabs, TabList, TabPanels, Tab, TabPanel,FormControl,FormLabel} from '@chakra-ui/react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { RiMoneyEuroBoxLine } from 'react-icons/ri';

const LANGUAGE_LABELS = {
  en: 'English',
  'zh-Hans': 'Traditional Chinese',
  'zh-Hant': 'Simplified Chinese',
};
const BaseAddEditModal = ({
  isOpen,
  onClose,
  onSave,
  initialData,
  dataStructure,
  tableName,
  isEdit,
  isMultiLanguageSupported = false,
  primaryKey,
}) => {
  const history = useHistory();
  const fileInputRef = useRef(isMultiLanguageSupported ? {} : null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isTranslatorReady, setIsTranslatorReady] = useState(false);
  const modalSize = useBreakpointValue({ base: "sm", md: "2xl", lg: "4xl" });
  const keysWithTranslateButton = ['caption', 'detail', 'tnc'];
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [activeLanguageTab, setActiveLanguageTab] = useState(0); 
  const TABLE_SYNC_CONFIG = {
    activity_prize: ['amount', 'probability','type'], 
    activity_translations: ['event_start', 'event_end', 'publish_start', 'publish_end', 'play_type', 'callback_url', 'status','draw_attempts'],
  };
  const [multiImagePreview, setMultiImagePreview] = useState({
    isOpen: false,
    currentIndex: 0,
    images: [],
  });
  const SYNC_FIELDS = TABLE_SYNC_CONFIG[tableName] || [];

  const translations = {
    en: {
      editItem: "Edit Item",
      addItem: "Add Item"
    },
  };

  const getTranslation = (tableName, action) => {
    if (tableName === "activity_translations") {
      return action === "editItem" ? "Edit Activity" : "Add Activity";
    }else  if (tableName === "game") {
      return action === "editItem" ? "Edit Game" : "Add Game";
    }else if (tableName === "activity_prize") {
      return action === "editItem" ? "Edit Prize" : "Add Prize";
    }else if (tableName === "events_translation") {
      return action === "editItem" ? "Edit Event" : "Add Event";
    }
    return translations.en[action]; 
  };
  const supportedLanguage = ['en', 'zh-Hant', 'zh-Hans']
  const [prize, setPrizeData] = useState({ prize: [] });
  const initializeTranslations = () => {
    return supportedLanguage.reduce((acc, language) => {
      acc[language] = Object.keys(dataStructure).reduce((fieldAcc, key) => {
     
          fieldAcc[key] = '';
   
        return fieldAcc;
      }, {});
      return acc;
    }, {});
  };

  const [data, setData] = useState(() => {
    const baseData = {
      ...initialData || {},
      translations: (() => {
        const translations = initialData?.translations || initializeTranslations();
        
        if (tableName === 'activity_translations' && initialData?.prize) {
          supportedLanguage.forEach(lang => {
            translations[lang] = {
              ...translations[lang],
              prize: [...initialData.prize] // 深拷贝数组
            };
          });
        }
        
        return translations;
      })()
    };
    if (tableName === 'activity_translations') {
      delete baseData.prize;
    }
    return baseData;
  });
  const [previewImage, setPreviewImage] = useState(initialData?.image_url || null); 

  useEffect(() => {

    if (isOpen) {
      let translations = {};
      if (isMultiLanguageSupported) {
        translations = initialData.translations || {}; 
      }
  
      setData({
        ...initialData,
        translations: translations,
      });
      if (tableName === 'member_tier_config'){
        setPreviewImage(initialData?.card_face || null);
      }else{
        setPreviewImage(initialData?.image_url || null);
      }
      setAlert(null);
      setLoading(false);

      console.log("Initial Data:", initialData);
    }
  }, [isOpen, initialData, isMultiLanguageSupported]);

  useEffect(() => {
    ensureConvertersInitialized().then(() => setIsTranslatorReady(true));
  }, []);

  const handleChange = useCallback((field, value, language = null) => {
    setData((prevData) => {
      const updatedTranslations = { ...prevData.translations };
      
      if (language) {
      
        updatedTranslations[language] = {
          ...(updatedTranslations[language] || {}),
          [field]: value,
        };

        if (SYNC_FIELDS.length > 0 && SYNC_FIELDS.includes(field)) {
          supportedLanguage.forEach((lang) => {
            if (lang !== language) {
              updatedTranslations[lang] = {
                ...(updatedTranslations[lang] || {}),
                [field]: value,
              };
            }
          });
        }

        return {
          ...prevData,
          translations: updatedTranslations,
        };
      } else {
        return { ...prevData, [field]: value };
      }
    });
  }, [SYNC_FIELDS, supportedLanguage]);
  
  useEffect(() => {
    console.log(data.translations, 'Updated translations');
  }, [data.translations]);

  const handleImageUpload = (url, language) => {
    if (isMultiLanguageSupported) {
      handleChange('image_url', url, language);
    }else{
      handleChange('image_url', url);
    }
    
  };

  const handleImageDelete = (language) => {
    console.log("handleImageDelete",3839289)
    if (isMultiLanguageSupported == true) {
      handleChange("image_url", "", language); 
      fileInputRef.current[language].value = null;
 
    }else {
      handleChange("image_url", ""); 
      fileInputRef.current.value = null;
    }
  };

  const validateFields = useCallback(() => {
    const newErrors = {};
    const updatedTranslations = { ...data.translations };
   console.log(updatedTranslations,838838388383)
    if (!isMultiLanguageSupported) {
      if (tableName === "member_tier_config"){
        if (data['image_url']) {
          data['card_face'] = data['image_url'];
        }else  {
          data['card_face'] = "" ;
        }
        if (!data['tier']) {
          data['tier'] = 'gold'; 
        }
      }
      Object.entries(dataStructure).forEach(([key, field]) => {
        if (field.required && (!data[key] || data[key].toString().trim() === '')) {
          newErrors[key] = `${field.label} is required;`;
        }
      });
      
    }else{
      supportedLanguage.forEach((language) => {
        const translation = data.translations[language];
        if (translation) {
            const updatedTranslation = { ...translation };

            // 只在 image_url 不存在时设置默认值
            if (!updatedTranslation.image_url && tableName == "events_translation") {
                updatedTranslation.image_url = "https://ibcmqseevofryxpilmwv.supabase.co/storage/v1/object/public/icon/event.jpg";
            }

            Object.entries(dataStructure).forEach(([key, field]) => {
                // 只在字段是必需的情况下检查 translation
                if (field.required) {
                    if (!translation[key]) { // 检查 translation 中的字段是否为空
                        newErrors[`${language}-${key}`] = `${field.label} is required in ${LANGUAGE_LABELS[language]};`;
                    }
                }
            });
            updatedTranslations[language] = updatedTranslation;
        } else {
            newErrors[language] = `${LANGUAGE_LABELS[language]} translations are missing`;
        }
    });
  
      // 在验证完成后，使用 setData 更新状态
      setData(prevData => ({
        ...prevData,
        translations: updatedTranslations,
      }));
    }
    return newErrors;
  }, [data, dataStructure, supportedLanguage]);

  const handleMultiImagePreview = (imageUrl, images) => {
    console.log("handleMultiImagePreview", imageUrl, images);
    const currentIndex = images.indexOf(imageUrl);
    setMultiImagePreview({
      isOpen: true,
      currentIndex,
      images
    });
  };

  const createCleanData = (data) => {
    const translations = Object.entries(data.translations || {}).map(([language, translation]) => {
      const cleanTranslation = { language, ...translation };
      if (!cleanTranslation.image_url) {
        cleanTranslation.image_url = "https://ibcmqseevofryxpilmwv.supabase.co/storage/v1/object/public/icon/event.jpg";
       
      }
   
      delete cleanTranslation["updated_at"];
      delete cleanTranslation["created_at"];
  
      return cleanTranslation;
    });
  
    return {
      translations,
    };};

    const createGameCleanData = (data) => {

      const translations = Object.entries(data.translations || {}).map(([language, translation]) => {
        const cleanTranslation = { language, ...translation };
        delete cleanTranslation["updated_at"];
        delete cleanTranslation["created_at"];
        return cleanTranslation;
      });
      const result = isEdit 
      ? { translations: translations }     
      : { translations: { translations } };
     
  if (isEdit && initialData.id) {
    result.id = initialData.parentId;
  }

  return result;
};
  

const createCleanPrizeData = (data) => {
  const activityId = localStorage.getItem('itemId');
  const translationEn = data.translations?.en || {};

  const cleanData = {
    "p_activity_id": parseInt(activityId, 10) || 1,
    "p_amount": translationEn.amount || '',
    "p_probability": translationEn.probability || '',
    translations: Object.entries(data.translations || {}).map(([language, translation]) => {

      return {
        ...(isEdit && { id: translation.id }),
        language,
        type: translation.type || 'Digital Reward',
        image: translation.image || '',
        name: translation.name || '',
        detail: translation.detail || '',
      };
    }),
  };

  // 编辑模式下补充父级ID
  if (isEdit && initialData?.id) {
    cleanData.id = data.parentId || initialData.id;
  }

  return cleanData;
};

    const createCleanActivityData = (data) => {
 
   // 提取英文翻译
   const translationEn = data.translations.en || {};

   const cleanData = {
     p_play_type: translationEn.play_type || 'draw_code', // 从 translations 中提取 play_type
     p_game_id: parseInt(translationEn.game_id, 10) || 1, // 从 translations 中提取 game_id，确保是整数
     p_event_start: translationEn.event_start || '', // 从 translations 中提取 event_start
     p_event_end: translationEn.event_end || '', // 从 translations 中提取 event_end
     p_publish_start: translationEn.publish_start || '', // 从 translations 中提取 publish_start
     p_publish_end: translationEn.publish_end || '', // 从 translations 中提取 publish_end
     p_callback_url: translationEn.callback_url || '', // 从 translations 中提取 callback_url
     p_status: translationEn.status || 'draft', // 从 translations 中提取 status，默认值
     p_draw_attempts: translationEn.draw_attempts || 0,
     translations: Object.entries(data.translations || {}).map(([language, translation]) => ({
       language,
       title: translation.title || '', // 确保 title 存在
       image: translation.image || '', // 确保 image 存在
       protocol_url: translation.protocol_url || '', // 确保 protocol_url 存在
       detail: translation.detail || '', // 确保 detail 存在
     })),
   };
   const dataActivity =  {translations : cleanData}
   return dataActivity;
    };
  
  const handleSave = useCallback(async () => {
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setAlert({
        title: 'Validation Error',
        description: Object.values(newErrors).join('\n'),
        status: 'error',
      });
      return;
    }
  
    setLoading(true);
    setAlert(null)
    console.log(isMultiLanguageSupported, 'isMultiLanguageSupported')
    if (isMultiLanguageSupported) {
    try {
        const operation = TableOperations[tableName] || TableOperations.default;
        const id = initialData.user_id ?? initialData.id;
        if (isEdit) {
          if (tableName == 'game'){
            const p_data = createGameCleanData(data);
            console.log(p_data, 'p_data')
            await operation.update({p_data});
            
           }else if(tableName == 'activity_prize'){
            const p_data = createCleanPrizeData(data);
            console.log(p_data, 'p_data')
            await operation.update({p_data});
         
           }else{
            const cleanData = createCleanData(data);
            cleanData["p_event_id"] = data.parentId;
            await operation.update(cleanData);
           }
        } else {
          if(tableName == 'activity_translations'){
            const cleanActivityData = createCleanActivityData(data);
            await operation.add(cleanActivityData);
            
          }
          else if(tableName == 'activity_prize'){
            const cleanPrizeData = createCleanPrizeData(data);
            await operation.add(cleanPrizeData);
           
          }else if(tableName == 'game'){
            const cleanGameRecordData = createGameCleanData(data);
            await operation.add(cleanGameRecordData);
          }else{
            const cleanData = createCleanData(data);
            await operation.add(cleanData);
          }
        }

      if(tableName == 'activity_prize'){
        history.push({
          pathname: "/admin/activity/list",
          state: {tableName},
        });
        return
      }
      setAlert({ title: 'Success', description: 'Records updated successfully', status: 'success' });
      onClose();
      onSave();
      window.location.reload();
    } catch (error) {
      setAlert({ title: 'Error', description: error.message, status: 'error' });
    } finally {
      setLoading(false);
    }
  }else{
    try {
  
      setLoading(true);
      const cleanData = { ...data };
      Object.keys(cleanData).forEach((key) => {
        if (!dataStructure[key] && key !== "id") {
          delete cleanData[key];
        }
      });
  
      const id = initialData.id ?? initialData.user_id ;

      if (isEdit) {
        if(tableName == 'users_profile') {
          addComsuptionPoints(tableName, id,cleanData)
        }else{
          await global.genericapi.updateRecord(tableName, id,cleanData);
        }

      } else {
        await global.genericapi.addRecord(tableName, cleanData);
      }

      setAlert({ title: 'Success', description: 'Record updated successfully', status: 'success' });
      onClose();
      onSave();
    } catch (error) {
      setAlert({ title: 'Error', description: error.message, status: 'error' });
    } finally {
      setLoading(false);
    }
  }
  }, [data, initialData, onClose, onSave, tableName, validateFields, supportedLanguage]);
  
  const addComsuptionPoints = (tableName, id,cleanData) =>{
    console.log('User ID:', id);
    console.log('Clean Data:', cleanData);

    if(cleanData.points != null){
      const payload = {
        p_user_id: id,
        p_transaction_type: 'purchase',
        p_amount: cleanData.points,
        p_points:0,
        p_description:'Increase points',
        p_coupon_id:null,
        p_is_addition:true
      };
      TableOperations[tableName].update(payload)
      delete cleanData.points;
      delete cleanData.points_total;
      global.genericapi.updateRecord(tableName, id,cleanData);
    }
  };

  const renderImageField = useCallback(
    (language = null) => {
      let imageUrl;
      if (isMultiLanguageSupported) {
        imageUrl = data.translations[language]?.image_url;
      } else if (tableName === 'member_tier_config') {
        imageUrl = data.card_face;
      } else {
        imageUrl = data.image_url;
      }

      // 上传处理函数
      const handleUpload = (imageUrl) => {
        if (isMultiLanguageSupported && language) {
          handleImageUpload(imageUrl, language);
        } else {
          handleImageUpload(imageUrl);
        }
      };
  
      const handleDelete = () => {
        console.log(73728,data.translations['en'])
        if (isMultiLanguageSupported && language) {
          handleImageDelete(language);
        } else {
          handleImageDelete();
        }
      };
  
      return (
        <FormControl mb={4}>
          <FormLabel>Image</FormLabel>
  
          {imageUrl && (
            <Box position="relative" display="inline-block" mb={2}>
              <Image
                src={imageUrl}
                alt="Uploaded"
                maxHeight="150px"
                onClick={() => {
                  setPreviewImage(imageUrl);
                  setIsImagePreviewOpen(true);
                }}
                style={{ cursor: "pointer" }}
              />
              <IconButton
                icon={<DeleteIcon />}
                onClick={handleDelete}
                position="absolute"
                top="3px"
                right="3px"
                size="sm"
                aria-label="Delete Image"
                color="gray.500"
                bg="gray.200"
                borderRadius="full"
                opacity="0.8"
                _hover={{ bg: "gray.300", opacity: 1 }}
                p="4px"
                _active={{ bg: "gray.400" }}
              />
            </Box>
          )}
  
          {/* 始终渲染 ImageField */}
          <FormFields.ImageField
            value={imageUrl || ""}
            onChange={handleUpload}
            onDelete={handleDelete}
            fileInputRef={fileInputRef}
            showPreview={false}
            tableName={tableName}
            language={language}
            isImageError={false}
            isMultiLanguageSupported={isMultiLanguageSupported}
          />
        </FormControl>
      );
    },
    [data, isMultiLanguageSupported, handleImageDelete, handleImageUpload, fileInputRef, tableName]
  );


  const handleTranslateClick = useCallback(async (field, language, currentValue) => {
    if (!isTranslatorReady) {
      console.log('Translator not ready yet');
      return;
    }

    let sourceLanguage, sourceText, targetLanguage;

    // Determine the source language based on the target language
    targetLanguage = language;
    if (language === 'zh-Hant') {
      sourceLanguage = 'zh-Hans';
    } else if (language === 'zh-Hans') {
      sourceLanguage = 'zh-Hant';
    } else {
      // For English or any other language, you might want to set a default behavior
      console.error('Unexpected language for translation');
      return;
    }

    if (currentValue) {
      // Use the current value if the field has text
      sourceText = currentValue;
    } else {
      // Find the source text from the other language translation
      const sourceTranslation = data.translations.find(t => t.language === sourceLanguage);
      if (!sourceTranslation || !(field in sourceTranslation)) {
        console.error(`Field ${field} not found in source translation`);
        return;
      }
      sourceText = sourceTranslation[field];
    }

    if (!sourceText) {
      console.error('No source text available for translation');
      return;
    }

    const translatedText = await handleTranslate(sourceText, sourceLanguage, targetLanguage);
    handleChange(field, translatedText, language);

  }, [data.translations, isTranslatorReady, handleChange]);
  const currentLanguage = supportedLanguage[activeLanguageTab];


  const renderField = useCallback((key, fieldType, language = null) => {

    if (key == 'image_url') {
      return renderImageField(language);
    }
    const { type, label, options, ...rest } = fieldType;

    let value = isMultiLanguageSupported
    ? data.translations?.[language]?.[key]
    : data[key]; 
    if ((type === "date" || type === 'dateTime') && (!value || isNaN(new Date(value).getTime()))) {
      value = ""; 
    }
    if (value === undefined || value === null) {
      value = ""; //  undefined or null 
    
    }
  
    const commonProps = {
      key: language ? `${language}-${key}` : key,
      label,
      value: String(value),
      onChange: (value) => handleChange(key, value, language),
      ...rest
    };
    const showTranslateButton = isMultiLanguageSupported &&  language && keysWithTranslateButton.includes(key);

    if (showTranslateButton) {
      commonProps.showTranslateButton = true;
      commonProps.onTranslate = () => handleTranslateClick(key, language, value);
    }

    switch (type) {
      case 'rich-text':
        return (
          <Box key={key} mb={4}>
            <Text fontWeight={500} mb={2}>{label}</Text> {/* show title */}
            <SunEditor
              setOptions={{
                height: 150, 
                zIndex: -9999,
                buttonList: [
                  ['undo', 'redo'],
                  ['font', 'fontSize', 'formatBlock'],
                  ['paragraphStyle', 'blockquote'],
                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                  ['fontColor', 'hiliteColor', 'textStyle'],
                  ['removeFormat'],
                  '/',
                  ['outdent', 'indent'],
                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                  ['table', 'link', 'image', 'video', 'audio' /** ,'math' */],

                  ['fullScreen', 'showBlocks', 'codeView'],
                  ['preview', 'print'],
                  ['save', 'template'],
                ]
              }}
              setContents={String(value || "")}
              onChange={(newContent) => handleChange(key, newContent, language)}
            />
          </Box>
        );
      case 'text':
        return <FormFields.TextField {...commonProps} />;
      case 'textarea':
        return <FormFields.TextareaField {...commonProps} />;
      case 'date':
        return <FormFields.DateField {...commonProps} />;
      case 'dateTime':
        return <FormFields.DateTimeField {...commonProps} />;
      case 'image':
        return (
          <FormFields.ImageField
            {...commonProps}
            tableName={tableName}
            fileInputRef={fileInputRef}
            isMultiLanguageSupported={isMultiLanguageSupported}
            language={language}
            onDelete={() => handleActivityImageDelete(language)} 
            onPreview={(value) => {
              setPreviewImage(value);
              setIsImagePreviewOpen(true);
            }}
          />
        );
      case 'inputNum':
          return <FormFields.InputNumberField {...commonProps} />;
      case 'select':
        return <FormFields.SelectField {...commonProps} options={options}/>;
      case 'switch':
        return <FormFields.SwitchField {...commonProps} />;
      case 'number':
        return <FormFields.NumberField {...commonProps} />;
      case 'radio':
        return <FormFields.RadioField {...commonProps} options={options} />;
      case 'color':
        return <FormFields.ColorField {...commonProps} />;
      case 'checkbox':
        return <FormFields.CheckboxField {...commonProps} options={options} />;
        case 'MultiImageField':
      return (
        <MultiImageField
          {...commonProps}
          onDelete={handleImageMultiDelete}
          value={data.translations[currentLanguage]?.display_images || []}
          tableName={tableName}
          fileInputRef={fileInputRef}
          isMultiLanguageSupported={isMultiLanguageSupported}
          language={language}
          maxCount={options?.maxCount}
          onPreviewOpen={(imgUrl) => {
            //游戏表上传多张照片的情况display_images
            const currentImages = isMultiLanguageSupported 
              ? data.translations?.[currentLanguage]?.display_images || []
              : data.images || [];
            handleMultiImagePreview(imgUrl, currentImages);
          }}
          onPreviewClose={() => setMultiImagePreview(prev => ({...prev, isOpen: false}))}
        />
      );
      case 'PrizeInputField':
        return (
          <FormFields.PrizeInputField
          prize={
            tableName === 'activity_translations' 
              ? data.translations?.[currentLanguage]?.prize || []
              : data.prize || []
          }
            onAdd={handleAddPrize}
            onChange={(index, field, value) => handlePrizeChange(index, field, value)}
            onRemove={handleRemovePrize}
          />
        )
      default:
        return null;
    } 
  }, [data, handleChange, handleTranslateClick]);


  const handleActivityImageDelete = ((language)=> {
    if (isMultiLanguageSupported && language) {
      handleActivityDelete(language);
    } else {
      handleActivityDelete();
    }
  });

  const handleActivityDelete = (language) => {
    if (isMultiLanguageSupported == true) {
      handleChange("image", "", language); 
      fileInputRef.current[language].value = null;
 
    }else {
      handleChange("image", ""); 
      fileInputRef.current.value = null;
    }
  };

  const modalTitle = isEdit
  ? getTranslation(tableName, "editItem")
  : getTranslation(tableName, "addItem");

    const handleImageMultiDelete = (newImages, removedIndex) => {
      setData((prevData) => {
        const updatedTranslations = { ...prevData.translations };
        const currentImages = updatedTranslations[currentLanguage].display_images || [];
    
        // 删除指定索引的图片
        const updatedImages = currentImages.filter((_, i) => i !== removedIndex);
    
        // 更新当前语言的 display_images
        updatedTranslations[currentLanguage] = {
          ...updatedTranslations[currentLanguage],
          display_images: updatedImages,
        };
    
        return {
          ...prevData,
          translations: updatedTranslations,
        };
      });
    };
  
//prize
    const handleAddPrize = () => {
      const newPrize = { name: '', amount:0, probability:0, remaining_quantity: 0 ,type:'Digital Reward'};
      
      if (tableName === 'activity_translations') {
        setData(prev => ({
          ...prev,
          translations: {
            ...prev.translations,
            [currentLanguage]: {
              ...prev.translations[currentLanguage],
              prize: [
                ...(prev.translations[currentLanguage]?.prize || []),
                newPrize
              ]
            }
          }
        }));
      } else {
        setData(prev => ({
          ...prev,
          prize: [...(prev.prize || []), newPrize]
        }));
      }
    };

    const handleRemovePrize = (index) => {
      if (tableName === 'activity_translations') {
        setData(prev => {
          const newTranslations = { ...prev.translations };
          const currentLangData = newTranslations[currentLanguage];
          
          if (currentLangData?.prize) {
            const updatedPrizes = currentLangData.prize.filter((_, i) => i !== index);
            newTranslations[currentLanguage] = {
              ...currentLangData,
              prize: updatedPrizes
            };
          }
          
          return { 
            ...prev,
            translations: newTranslations 
          };
        });
      } else {
        setData(prev => ({
          ...prev,
          prize: prev.prize.filter((_, i) => i !== index)
        }));
      }
    };

    const handlePrizeChange = (index, field, value) => {
      if (tableName === 'activity_translations') {
        setData(prev => {
          const newTranslations = { ...prev.translations };
          const prizes = [...(newTranslations[currentLanguage]?.prize || [])];
          
          if (index >= 0 && index < prizes.length) {
            prizes[index][field] = value;

            // 如果修改的是 amount，更新 remaining_quantity
            if (field === 'amount') {
              prizes[index].remaining_quantity = value; // 同步 remaining_quantity
            }

            newTranslations[currentLanguage].prize = prizes;
          }
          
          return { ...prev, translations: newTranslations };
        });
      } else {
        const updatedPrizes = [...data.prize];
        if (updatedPrizes[index]) {
          updatedPrizes[index][field] = value;

          // 如果修改的是 amount，更新 remaining_quantity
          if (field === 'amount') {
            updatedPrizes[index].remaining_quantity = value; // 同步 remaining_quantity
          }

          setData(prev => ({ ...prev, prize: updatedPrizes }));
        }
      }
    };

  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {alert && (
            <Alert status={alert.status} mb={4}>
              <AlertIcon />
              <Box>
                <Box fontWeight="bold">{alert.title}</Box>
                <Box>{alert.description}</Box>
              </Box>
            </Alert>
          )}
           {isMultiLanguageSupported ? (
            <Tabs index={activeLanguageTab}  onChange={(index) => {
              setActiveLanguageTab(index);
              setData(prev => ({ ...prev }));
            }} >
            <TabList>
              {Object.entries(LANGUAGE_LABELS).map(([code, label]) => (
                <Tab key={code}>{label}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {supportedLanguage.map(language => (
                <TabPanel key={language}>
                  {Object.entries(dataStructure).map(([key, field]) => (
                    <Box key={key}>
                      {renderField(key, field, language)}
                    </Box>
                  ))}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
       )  : (
          Object.entries(dataStructure).map(([key, field]) => renderField(key, field))
        )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
          <Button colorScheme="blue" onClick={handleSave} ml={3} isLoading={loading}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

    {(isImagePreviewOpen || multiImagePreview.isOpen) && (
  <Modal 
    isOpen 
    onClose={() => {
      setIsImagePreviewOpen(false);
      setMultiImagePreview(prev => ({...prev, isOpen: false}));
    }}
    size="full" 
  >
    <ModalOverlay />
    <ModalContent 
      bg="transparent" 
      boxShadow="none"
      maxW="90vw"
      mx="auto" >
      <ModalCloseButton color="black" />
      <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="center" 
        h="100vh"
      >
        {/* 单图预览 */}
        {previewImage && (
          <Image src={previewImage} maxH="90vh" />
        )}
        
        {/* 多图预览 */}
        {multiImagePreview.images.length > 0 && (
          <>
            <Image 
              src={multiImagePreview.images[multiImagePreview.currentIndex]}
              maxH="90vh"
            />
            <Flex position="absolute" top="50%" w="full" px={8}>
              <IconButton
                icon={<MdChevronLeft />}
                onClick={() => setMultiImagePreview(prev => ({
                  ...prev, 
                  currentIndex: Math.max(0, prev.currentIndex - 1)
                }))}
              />
              <IconButton
                icon={<MdChevronRight />}
                onClick={() => setMultiImagePreview(prev => ({
                  ...prev,
                  currentIndex: Math.min(prev.images.length - 1, prev.currentIndex + 1)
                }))}
                ml="auto"
              />
            </Flex>
          </>
        )}
      </Box>
    </ModalContent>
  </Modal>
)}
</>
  );
};

export default BaseAddEditModal;