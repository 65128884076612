import React, { useState, useEffect } from 'react';
import {
  HStack,
  Button,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

const InputNumberField = ({ label, min = 0, max = 1000000, step = 1, defaultValue = 0, onChange, ...rest }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(Number(defaultValue));
  }, [defaultValue]);

  const handleDecrement = () => {
    const newValue = Math.max(value - step, min);
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleIncrement = () => {
    const newValue = Math.min(value + step, max);
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleChange = (e) => {
    const inputValue = Number(e.target.value);
    if (!isNaN(inputValue)) {
      const newValue = Math.min(Math.max(inputValue, min), max);
      setValue(newValue);
      if (onChange) onChange(newValue);
    }
  };

  const handleFocus = () => {
    if (value === 0) {
      setValue(''); 
    }
  };

  const handleBlur = () => {
    if (value === '' || value < min) {
      setValue(min); 
      if (onChange) onChange(min);
    } else {
      if (onChange) onChange(value);
    }
  };

  return (
    <FormControl>
      {label && <FormLabel mb={2}>{label}</FormLabel>}
      <HStack
        border="1px solid"
        borderColor="gray.300"
        borderRadius="md"
        maxW="200px"
        spacing={0}
        {...rest}
      >
        <Button
          size="sm"
          w="40px"
          h="36px"
          onClick={handleDecrement}
          borderRight="1px solid"
          borderColor="gray.300"
          bg="gray.300"
          rounded="none"
          disabled={value <= min}
        >
          -
        </Button>
        <Input
          type="number"
          value={value}
          onChange={handleChange}
          onFocus={handleFocus} 
          onBlur={handleBlur} 
          textAlign="center"
          border="none"
          w="120px"
          h="36px"
          _focus={{ outline: 'none' }}
          _hover={{ border: 'none' }}
          px={0}
          borderRadius="none" 
        />
        <Button
          size="sm"
          w="40px"
          h="36px"
          onClick={handleIncrement}
          borderLeft="1px solid"
          borderColor="gray.300"
          bg="gray.300"
          rounded="none"
          disabled={value >= max}
        >
          +
        </Button>
      </HStack>
    </FormControl>
  );
};

export default InputNumberField;
