import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React, { useState,useEffect } from 'react';
import TableOperations from '../../components/base/utils/TableOperations';
const AddEditActivityModal = ({ isOpen, onClose, initialData, onSave, isEdit }) => {
  console.log("AddEditActivityModal Initial Data:", initialData);
  const [data, setData] = useState({
    prize: initialData?.prize || [], // 确保初始化为数组
    // ...其他字段
  });
  const [gameOptions, setGameOptions] = useState([]);

  // 获取游戏选项
  const fetchGameOptions = async () => {
    try {
      const options = await TableOperations['game_select'].query()
      const formattedOptions = options.map(game => ({
        value: game.game_id, 
        label: game.name 
      }));
      setGameOptions(formattedOptions);
      console.log(options, 'options')
    } catch (error) {
      console.error("Error fetching game options:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchGameOptions(); // 在模态框打开时获取游戏选项
    }
  }, [isOpen]);
  const dataStructure = {
    title: { type: 'text', label: 'Title', required: true},
    image: { type: 'image', label: 'Image', required: true },
    game_id: { type: 'select', label: 'Game', required: false, options: gameOptions },
    draw_attempts: { type: 'number', label: 'Draw Attempts', required: true },
    callback_url: { type: 'text', label: 'Callback URL', required: false },
    protocol_url: { type: 'text', label: 'Protocol URL', required: true },
    publish_start: {
      type: 'dateTime',
      label: 'Publish Start',
      required: true
    },
    publish_end: {
      type: 'dateTime',
      label: 'Publish End',
      required: true
    },
    event_start: {
      type: 'dateTime',
      label: 'Event Start',
      required: true
    },
    event_end: {
      type: 'dateTime',
      label: 'Event End',
      required: true
    },
    play_type: {
      type: "select",
      label: 'Play Type',
      required: false,
      options: [
        { value: 'draw code', label: 'Draw Code' },
        { value: 'email', label: 'Email' },
        { value: 'phone number', label: 'Phone Number' },
      ]
    },
    status: {
      type: "select",
      label: 'Status',
      required: false,
      options: [
        { value: 'draft', label: 'Draft' },
        { value: 'active', label: 'Active' },
        { value: 'in_active', label: 'Inactive' },
        { value: 'completed', label: 'Completed' },
      ]
    },
    detail: { type: 'textarea', label: 'Detail', required: false },
  };

  return (
    <BaseAddEditModal
      isEdit={isEdit}
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="activity_translations"
      isMultiLanguageSupported={true}
    >
    </BaseAddEditModal>
  );
};

export default AddEditActivityModal;
