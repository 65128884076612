import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditProtocolModal = ({ isOpen, onClose, initialData, onSave, isEdit }) => {
    const dataStructure = {
        protocol: { type: 'text', label: 'Protocol', required: true },
    };

    return (
        <BaseAddEditModal
            isEdit={isEdit}
            isOpen={isOpen}
            onClose={onClose}
            initialData={initialData}
            onSave={onSave}
            dataStructure={dataStructure}
            tableName="protocol"
            isMultiLanguageSupported={true}
        />
    );
};

export default AddEditProtocolModal; 