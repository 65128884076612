import React, { useState, useRef } from 'react';
import { Box, Input, Image, IconButton, Flex, Text, useColorModeValue, Icon, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/react';
import { MdPhoto } from 'react-icons/md';
import { DeleteIcon } from '@chakra-ui/icons';

const MultiImageUpload = ({
  onPreviewOpen,      
  onPreviewClose,    
  initialPreviewIndex = 0, // 初始预览索引
  label,
  value: propValue = [],
  onChange,
  tableName,
  language = 'default',
  isMultiLanguageSupported = false,
  fileInputRef: propFileInputRef,
  onDelete,
  ...rest
}) => {
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(initialPreviewIndex);
  const [localImages, setLocalImages] = useState([]); // 本地状态，用于没有传递 value 的情况
  const [uploadingIndex, setUploadingIndex] = useState(-1); // 当前上传的图片索引
  const iconColor = useColorModeValue('gray.300', 'gray.300');

  // 独立的 fileInputRef，避免与 ImageField 冲突
  const fileInputRef = useRef(null);

  // 根据 propValue 是否为空，决定使用 propValue 还是 localImages
  const images = propValue.length > 0 ? propValue : localImages;

// 修改预览触发逻辑
const handlePreviewImage = (index) => {
  const imageUrl = images[index];

  setCurrentPreviewIndex(index);
  onPreviewOpen?.(images[index]); // 通知父组件
};

// 修改模态框关闭逻辑
const handleClosePreview = () => {
  onPreviewClose?.();
};
  // 处理图片上传
  const handleImageChange = async (event) => {
    const files = Array.from(event.target.files);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setUploadingIndex(images.length + i); // 设置当前上传的索引
      try {
        const folder = tableName === 'icon_setting' ? 'icon' :
          tableName === 'tutorial_settings' ? 'tutorial' : 'icon';
        const publicUrl = await global.genericapi.uploadImageAndGetPublicUrl(folder, file);
        console.log('Uploaded image URL:', publicUrl); // 打印上传的图片 URL
        if (publicUrl) {
          const newImages = [...images, publicUrl]; // 将新图片 URL 添加到数组中
          if (propValue.length > 0) {
            onChange(newImages); // 如果父组件传递了 value，触发 onChange
          } else {
            setLocalImages(newImages); // 如果父组件没有传递 value，更新本地状态
            onChange(newImages); // 触发 onChange
          }
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setUploadingIndex(-1); // 重置上传索引
      }
    }
  };

  // 删除图片
  const handleRemoveImage = (index) => {
    console.log('Removing image at index:', index); // 调试信息
    const newImages = images.filter((_, i) => i !== index); // 过滤掉要删除的图片
    if (propValue.length > 0) {
        onDelete(newImages,index); // 如果父组件传递了 value，触发 onChange
    } else {
        setLocalImages(newImages); // 如果父组件没有传递 value，更新本地状态
        onDelete(newImages,index); // 触发 onChange
    }
  };

  // 处理多语言的点击事件
  const handleClick = () => {
    if (isMultiLanguageSupported) {
      if (fileInputRef.current && fileInputRef.current[language]) {
        fileInputRef.current[language].click();
      } else {
        console.error(`fileInputRef 的 language ${language} 未定义`);
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      } else {
        console.error('fileInputRef 未定义');
      }
    }
  };

  return (
    <FormControl mb={4}>
      <FormLabel>{label}</FormLabel>
      <Input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageChange}
        style={{ display: 'none' }}
        id="multi-image-upload"
        ref={(ref) => {
          if (isMultiLanguageSupported) {
            if (!fileInputRef.current) {
              fileInputRef.current = {};
            }
            fileInputRef.current[language] = ref;
          } else {
            fileInputRef.current = ref;
          }
        }}
      />
      <Flex
        flexWrap="wrap"
        gap={4}
        mt={4}
        alignItems="flex-start"
        flexDirection={images.length === 0 ? 'row' : 'row-reverse'}
        justifyContent={images.length === 0 ? 'flex-start' : 'flex-end'}
      >
        {/* 上传按钮 */}
        <Box
          flex="0 0 auto"
          order={images.length === 0 ? 0 : 1}
          width={160}
          height={160}
          border="1px"
          borderStyle="dashed"
          borderColor="gray.300"
          p={1}
          cursor="pointer"
          onClick={handleClick}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box color={iconColor}>
            <Icon as={MdPhoto} boxSize={30} color={iconColor} />
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.500">
              Click to upload images
            </Text>
          </Box>
        </Box>
        {/* 图片展示区域 */}
        <Flex flexWrap="wrap" gap={4} order={images.length === 0 ? 1 : 0}>
          {images.map((image, index) => (
            <Box key={index} position="relative">
              <Image
                boxSize="160px"
                objectFit="contain"
                src={image}
                alt={`Uploaded image ${index + 1}`}
                onClick={() => handlePreviewImage(index)}
              />
              <IconButton
                icon={<DeleteIcon />}
                onClick={() => handleRemoveImage(index)}
                position="absolute"
                top="3px"
                right="3px"
                size="sm"
                aria-label="Delete Image"
                color="gray.500"
                bg="gray.200"
                borderRadius="full"
                opacity="0.8"
                _hover={{ bg: "gray.300", opacity: 1 }}
                p="4px"
                _active={{ bg: "gray.400" }}
              />
            </Box>
          ))}
          {uploadingIndex !== -1 && (
            <Box
              width={150}
              height={150}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="sm" color="gray.500">
                Uploading...
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>
    </FormControl>
  );
};

export default MultiImageUpload;