import React from 'react';
import { Box, Text, HStack, IconButton } from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const ProtocolCard = ({ item, onEdit, onDelete }) => {
    return (
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5}>
            <Text fontSize="xl" fontWeight="bold">{item.protocol}</Text>
            <HStack spacing={2} mt={4}>
                <IconButton icon={<EditIcon />} aria-label="Edit Protocol" onClick={() => onEdit(item)} />
                <IconButton icon={<DeleteIcon />} aria-label="Delete Protocol" onClick={() => onDelete(item)} />
            </HStack>
        </Box>
    );
};

export default ProtocolCard;