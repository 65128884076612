import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdBrokenImage,
  MdLogout,
  MdManageAccounts,
  MdManageHistory,
  MdNotificationAdd,
  MdSpaceDashboard,
} from "react-icons/md";


import UserAccounts from "views/admin/users/accounts";
import UserLog from "views/admin/users/log";

import BannersHome from "views/admin/promotion/home";
import BannersMain from "views/admin/promotion/main";

import AppIcon from "views/admin/setting/app/icon";
import AppTheme from "views/admin/setting/app/theme";
import AppTutorial from "views/admin/setting/app/tutorial";
import AppTabbar from "views/admin/setting/app/tabbar";
import AppLeftBar from "views/admin/setting/app/leftbar";
import AppLanguage from "views/admin/setting/app/language";
import AppVersion from "views/admin/setting/versions/list";
import VersionList from "views/admin/setting/versions/list";
import Maintainence from "views/admin/setting/maintainence";

import LatestNews from "views/admin/latestnews";
import StoreInfo from "views/admin/storelocator/storeinfo";
import StoreRegions from "views/admin/storelocator/regions";
import membershipInfo from "views/admin/membership/memberInfo";
import MembershipTier from "views/admin/membership/tier";
import RewardCoupons from "views/admin/reward/coupons";
import RewardRedemption from "views/admin/reward/redemption";
import MemberInfo from "views/admin/dashboard";
import GenericNotification from "views/admin/pushnotification/generic";
import EventList from "views/admin/event/eventList";
import EventDetail from "views/admin/event/eventDetail";
import eventRegistration from "views/admin/event/Registration";
// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignOutCentered from "views/auth/signOut";

// Preview Imports
import QuestionnairePage from "views/preview";

import ActivityList from "views/admin/activity/list";
import ActivityDetail from "views/admin/activity/detail";
import ActivityDashboard from "views/admin/activity/dashboard";
import GameList from "views/admin/activity/game";

import ProtocolSettings from "views/admin/protocol/list";

const routes = [
  {
    name: "User Accounts",
    layout: "/admin",
    path: "/users/accounts",
    component: UserAccounts
  },
  {
    name: "User Activity Logs",
    layout: "/admin",
    path: "/users/log",
    component: UserLog
  },
  {
    name: "Force Update",
    layout: "/admin",
    path: "/setting/versions/list",
    component: AppVersion,
  },
  {
    name: "Force Update",
    layout: "/admin",
    path: "/setting/versions/list",
    component: VersionList
  },
  {
    name: "Maintainence",
    layout: "/admin",
    path: "/setting/maintainence",
    component: Maintainence
  },
  {
    name: "App Icon",
    layout: "/admin",
    path: "/setting/app/icon",
    component: AppIcon
  },
  {
    name: "Theme",
    layout: "/admin",
    path: "/setting/app/theme",
    component: AppTheme
  },
  {
    name: "Tutorial Image",
    layout: "/admin",
    path: "/setting/app/tutorial",
    component: AppTutorial
  },
  {
    name: "Tab Menu",
    layout: "/admin",
    path: "/setting/app/tabbar",
    component: AppTabbar
  },
  {
    name: "Sliding Menu",
    layout: "/admin",
    path: "/setting/app/leftbar",
    component: AppLeftBar
  },
  {
    name: "Support Language",
    layout: "/admin",
    path: "/setting/app/language",
    component: AppLanguage
  },
  {
    name: "Main Banners",
    layout: "/admin",
    path: "/promotion/main",
    component: BannersMain
  },
  {
    name: "Home Banners",
    layout: "/admin",
    path: "/promotion/home",
    component: BannersHome
  },
  {
    name: "Latest News",
    layout: "/admin",
    path: "/latestnews",
    component: LatestNews
  },
  {
    name: "Store Info",
    layout: "/admin",
    path: "/storelocator/storeinfo",
    component: StoreInfo
  },
  {
    name: "Regions",
    layout: "/admin",
    path: "/storelocator/regions",
    component: StoreRegions
  },
  {
    name: "Member Info",
    layout: "/admin",
    path: "/membership/memberInfo",
    component: membershipInfo
  },
  {
    name: "Member Tier",
    layout: "/admin",
    path: "/membership/tier",
    component: MembershipTier
  },
  {
    name: "Event List",
    layout: "/admin",
    path: "/event/eventList",
    component: EventList
  },
  {
    name: "Event Detail",
    layout: "/admin",
    path: "/event/eventDetail",
    component: EventDetail
  },
  {
    name: "Event Registration",
    layout: "/admin",
    path: "/event/registration",
    component: eventRegistration
  },
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    component: MemberInfo
  },
  {
    name: "Coupons",
    layout: "/admin",
    path: "/reward/coupons",
    component: RewardCoupons
  },
  {
    name: "Redemption",
    layout: "/admin",
    path: "/reward/redemption",
    component: RewardRedemption
  },
  {
    name: "Push Notification",
    layout: "/admin",
    path: "/pushnotification/generic",
    component: GenericNotification
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    component: SignInCentered
  },
  {
    name: "Log Out",
    layout: "/auth",
    path: "/sign-out",
    component: SignOutCentered
  },
  {
    name: "Questionnaire",
    layout: "/preview",
    path: "/preview",
    component: QuestionnairePage
  },
  {
    name: "Activity List",
    layout: "/admin",
    path: "/activity/list",
    component: ActivityList
  },
  {
    name: "Activity Detail",
    layout: "/admin",
    path: "/activity/detail",
    component: ActivityDetail
  },
  {
    name: "Game List",
    layout: "/admin",
    path: "/activity/game",
    component: GameList
  },
  {
    name: "Activity Dashboard",
    layout: "/admin",
    path: "/activity/dashboard",
    component: ActivityDashboard
  },
  {
    name: "Protocol",
    layout: "/admin",
    path: "/protocol/list",
    component: ProtocolSettings
  },

];

export default routes;
