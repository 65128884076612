import {
  Box,
  Button,
  Alert,
  AlertIcon,
  Text,
  Image,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { DeleteIcon } from '@chakra-ui/icons';
import * as FormFields from "./FormFields";
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import TableOperations from './utils/TableOperations';
import { Tabs, TabList, TabPanels, Tab, TabPanel, FormControl, FormLabel } from '@chakra-ui/react';
import { useHistory } from "react-router-dom";
const InlineEditBox = ({
  initialData,
  onSave,
  dataStructure,
  tableName,
  isEdit = false,
  isMultiLanguageSupported = false,
  primaryKey
}) => {
  const fileInputRef = useRef(isMultiLanguageSupported ? {} : null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [activeLanguageTab, setActiveLanguageTab] = useState(0);
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(initialData?.image_url || null);
  const [translationData, setTranslationData] = useState(initialData.translations || {});
  const [fieldErrors, setFieldErrors] = useState({});
  const TABLE_SYNC_CONFIG = {
    activity_translations: ['draw_attempts','event_start', 'event_end', 'publish_start', 'publish_end', 'play_type', 'callback_url', 'status'],
    events_translation: ['registration_start', 'registration_end', 'publish_start', 'publish_end', 'event_date'],
  };
  const SYNC_FIELDS = TABLE_SYNC_CONFIG[tableName] || [];
  const history = useHistory();
  const supportedLanguage = ["en", "zh-Hant", "zh-Hans"];

  const LANGUAGE_LABELS = useMemo(() => ({
    en: 'English',
    'zh-Hant': 'Traditional Chinese',
    'zh-Hans': 'Simplified Chinese',
  }), []);
  const initializeTranslations = useCallback(() => {
    return supportedLanguage.reduce((acc, language) => {
      acc[language] = Object.keys(dataStructure).reduce((fieldAcc, key) => {
        fieldAcc[key] = "";
        return fieldAcc;
      }, {});
      return acc;
    }, {});
  }, [dataStructure, supportedLanguage]);

  const [data, setData] = useState({
    ...initialData,
    translations: {},
  });

  useEffect(() => {
    if (initialData) {
      const translationsMap = {};
      if (tableName === "activity_translations") {
        initialData.translations.forEach(translation => {
          translationsMap[translation.language] = {
            title: translation.title,
            game_id: translation.game,
            image: translation.image || '',
            status: translation.status,
            event_start: translation.event_start,
            event_end: translation.event_end,
            publish_start: translation.publish_start,
            publish_end: translation.publish_end,
            play_type: translation.play_type,
            callback_url: translation.callback_url,
            protocol_url: translation.protocol_url,
            draw_attempts: translation.draw_attempts,
            detail: translation.detail
          };
          console.log(translation,333333)
        });
console.log(initialData,83838388383,translationsMap)
      } else {
        initialData.translations.forEach(translation => {
          translationsMap[translation.language] = {
            name: translation.name,
            location: translation.location,
            image_url: translation.image_url || '',
            registration_start: translation.registration_start,
            registration_end: translation.registration_end,
            event_date: translation.event_date,
            publish_start: translation.publish_start,
            publish_end: translation.publish_end,
            detail: translation.detail,
          };
        });
      }

      setTranslationData(translationsMap);
      // 更新状态
      setData(prevData => ({
        ...prevData,
        translations: translationsMap,
      }));

    }
  }, [initialData]);

  const handleChange = useCallback((field, value, language = null) => {

    if (field === 'game_id') {
      setData(prev => ({
        ...prev,
        [field]: value // 直接更新顶层数据
      }));
      return;
    }
    setData(prevData => {
      const updatedTranslations = { ...prevData.translations };

      if (SYNC_FIELDS.length > 0 && SYNC_FIELDS.includes(field)) {
        supportedLanguage.forEach((lang) => {
          if (lang !== language) {
            updatedTranslations[lang] = {
              ...(updatedTranslations[lang] || {}),
              [field]: value,
            };
          }
        });
      }
  
      updatedTranslations[language] = {
        ...(updatedTranslations[language] || {}),
        [field]: value,
      };

      return { ...prevData, translations: updatedTranslations };
    });
  }, [SYNC_FIELDS]);

  const handleImageUpload = (url, language = null) => {
    if (isMultiLanguageSupported && language) {
      handleChange("image", url, language);
    } else {
      handleChange("image", url);
    }
  };

  const handleImageDelete = (language = null) => {
    if (isMultiLanguageSupported && language) {
      handleChange("image", "", language);
    } else {
      handleChange("image", "");
    }
  };

  const validFields = useCallback(() => {
    const newErrors = {};
    supportedLanguage.forEach(language => {
      const translation = data.translations[language] || {};
      console.log(translation, 2231211111, language)
      Object.entries(dataStructure).forEach(([key, field]) => {
        const value = translation[key];
        console.log(value,645646446,key)
        if (field.required && (!value || value.toString().trim() === "")) {
          newErrors[`${language}-${key}`] = `${field.label} is requried in（${LANGUAGE_LABELS[language]}）`;
        }
      });
    });
    return newErrors;
  }, [data.translations, dataStructure, supportedLanguage]);

  const handleSave = async () => {

    let payload;
    let p_event_id;
    const errors = validFields()
    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const currentData = data;

    const currentLanguage = supportedLanguage[activeLanguageTab];

    if (tableName == 'activity_translations') {
      console.log(data,9393993939939)
      const mainData = {
        activity_id: data.activityId || initialData.activityId,
        play_type: data.translations[currentLanguage]?.play_type,
        game_id: data.game_id,
        event_start: data.translations[currentLanguage]?.event_start,
        event_end: data.translations[currentLanguage]?.event_end,
        publish_start: data.translations[currentLanguage]?.publish_start,
        publish_end: data.translations[currentLanguage]?.publish_end,
        callback_url: data.translations[currentLanguage]?.callback_url,
        draw_attempts: data.translations[currentLanguage]?.draw_attempts,
        status: data.translations[currentLanguage]?.status,
      };

      // 构建翻译数据
      const translations = supportedLanguage.map(language => {
      const currentTranslation = data.translations[language] || {};
      const initialTranslation = initialData.translations.find(t => t.language === language) || {};

        return {
          id: initialTranslation.id || null, // 保留原始ID
          language,
          title: currentTranslation.title || "",
          image: currentTranslation.image || "",
          protocol_url: currentTranslation.protocol_url || "",
          detail: currentTranslation.detail || ""
        };
      });
      const p_data = {
        ...mainData,
        translations,
        activity_id: data.activityId || initialData.activityId
      };
      payload = { p_data };

    } else {
      const translationsData = supportedLanguage.map(language => {
        const currentTranslation = data.translations[language] || {};
        const initialTranslation = initialData.translations.find(t => t.language === language) || {};

        p_event_id = initialData.parentId || null;
        return {
          id: initialTranslation.id || "",
          language,
          name: currentTranslation.name || "",
          location: currentTranslation.location || "",
          registration_start: currentTranslation.registration_start || "",
          registration_end: currentTranslation.registration_end || "",
          event_date: currentTranslation.event_date || "",
          image_url: currentTranslation.image_url || "",
          publish_start: currentTranslation.publish_start || "",
          publish_end: currentTranslation.publish_end || "",
          detail: currentTranslation.detail || "",
        };

      });
      payload = { p_event_id, translations: translationsData };
    }

    try {
      const response = await TableOperations[tableName].update(payload);
      if (response && response.success === false) {
        setAlert({
          title: "Error",
          description: response.error || "Operation failed",
          status: "error",
        });
        return; 
      }
      setAlert({
        title: "Success",
        description: "Information updated successfully.",
        status: "success",
      });
      if (tableName === "activity_translations") {

        history.push({
          pathname: "/admin/activity/list",
          state: { tableName },
        });
      } else {
        history.push({
          pathname: "/admin/event/eventList",
          state: { tableName },
        });
      }

    } catch (error) {
      setAlert({
        title: "Error",
        description: error.message,
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };


  const renderImageField = useCallback((language = null) => {
    const imageFieldName = tableName === 'events_translation' ? 'image_url' : 'image';

    let imageUrl = isMultiLanguageSupported
      ? data.translations[language]?.[imageFieldName]
      : data[imageFieldName];

    const errorKey = `${language}-${imageFieldName}`;
    const errorMessage = fieldErrors[errorKey];
    // 上传处理函数
    const handleUpload = (url) => {
      handleChange(imageFieldName, url, language);
    };

    // 删除处理函数
    const handleDelete = () => {
      handleChange(imageFieldName, "", language); // 统一使用动态字段名
    };

    return (
      <FormControl isInvalid={!!errorMessage} mb={4}>
        <FormLabel>Image</FormLabel>
        {imageUrl && (
          <Box position="relative" display="inline-block" mb={2}>
            <Image
              src={imageUrl}
              alt="Uploaded"
              maxHeight="150px"
              onClick={() => {
                setPreviewImage(imageUrl);
                setIsImagePreviewOpen(true);
              }}
              style={{ cursor: "pointer" }}
            />
            <IconButton
              icon={<DeleteIcon />}
              onClick={handleDelete}
              position="absolute"
              top="3px"
              right="3px"
              size="sm"
              aria-label="Delete Image"
              color="gray.500"
              bg="gray.200"
              borderRadius="full"
              opacity="0.8"
              _hover={{ bg: "gray.300", opacity: 1 }}
              p="4px"
              _active={{ bg: "gray.400" }}
            />
          </Box>
        )}
        <FormFields.ImageField
          value={imageUrl || ""}
          onChange={handleUpload}
          fileInputRef={fileInputRef}
          showPreview={false}
          tableName={tableName}
          language={language}
          isImageError={false}
          isMultiLanguageSupported={isMultiLanguageSupported}
        />
        {errorMessage && (
          <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
        )}
      </FormControl>
    );
  },
    [data, isMultiLanguageSupported, handleImageDelete, handleImageUpload, fileInputRef, tableName]
  );

  const renderField = useCallback((key, fieldType, language = null) => {
    const { type, label, options, ...rest } = fieldType;
    let value = isMultiLanguageSupported
      ? data.translations?.[language]?.[key]
      : data[key];

    // 处理错误信息
    const errorKey = `${language}-${key}`;
    const errorMessage = fieldErrors[errorKey];

    // 处理值为 undefined 或 null 的情况
    if (value === undefined || value === null) {
      value = ""; // 将 undefined 或 null 转换为 ""
    }

    const commonProps = {
      key: language ? `${language}-${key}` : key,
      label,
      value: String(value || ""),
      onChange: (value) => handleChange(key, value, language),
      ...rest,
    };
console.log(commonProps,43244444)
    switch (type) {
      case "rich-text":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <Box key={key} mb={4}>
              <Text fontWeight={500} mb={2}>{label}</Text>
              <SunEditor
                setOptions={{
                  height: 150,
                  zIndex: -9999,
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["paragraphStyle", "blockquote"],
                    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                    ["fontColor", "hiliteColor", "textStyle"],
                    ["removeFormat"],
                    "/",
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["fullScreen", "showBlocks", "codeView"],
                    ["preview", "print"],
                  ],
                }}
                setContents={String(value || "")}
                onChange={(newContent) => handleChange(key, newContent, language)}
              />
            </Box>
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "text":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.TextField {...commonProps} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "textarea":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.TextareaField {...commonProps} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "date":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.DateField {...commonProps} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "dateTime":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.DateTimeField {...commonProps} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "image":
        return renderImageField(language);
      case "inputNum":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.InputNumberField {...commonProps} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "select":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.SelectField {...commonProps} options={options}  value={
        key === 'game_id' 
          ? data[key] // 顶层字段直接取值
          : data.translations[language]?.[key] // 翻译字段从对应语言取
          || '' // 处理空值情况
      } />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "switch":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.SwitchField {...commonProps} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "number":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.NumberField {...commonProps} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "radio":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.RadioField {...commonProps} options={options} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "color":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.ColorField {...commonProps} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      case "checkbox":
        return (
          <FormControl isInvalid={!!errorMessage} mb={4}>
            <FormFields.CheckboxField {...commonProps} options={options} />
            {errorMessage && (
              <Text color="red.500" fontSize="sm" mt={1}>{errorMessage}</Text>
            )}
          </FormControl>
        );
      default:
        return null;
    }
  }, [data, isMultiLanguageSupported, handleChange, renderImageField, fieldErrors]);

  return (
    <>
      <Box p="20px" borderWidth="1px" borderRadius="lg" mb="20px" boxShadow="md">
        {alert && (
          <Alert status={alert.status} mb={4}>
            <AlertIcon />
            <Box>
              <Box fontWeight="bold">{alert.title}</Box>
              <Box>{alert.description}</Box>
            </Box>
          </Alert>
        )}
        <Tabs index={activeLanguageTab} onChange={setActiveLanguageTab}>
          <TabList>
            {supportedLanguage.map((lang) => (
              <Tab key={lang}>{LANGUAGE_LABELS[lang]}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {supportedLanguage.map((language) => (
              <TabPanel key={language}>
                {Object.entries(dataStructure).map(([key, field]) =>
                  renderField(key, field, language)
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        <Box mt="4" textAlign="right">
          <Button colorScheme="blue" onClick={handleSave} isLoading={loading}>
            Save
          </Button>
        </Box>
      </Box>

      <Modal
        isOpen={isImagePreviewOpen}
        onClose={() => setIsImagePreviewOpen(false)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          bg="transparent"
          boxShadow="none"
          maxW="90vw"
          mx="auto"
        >
          <ModalCloseButton
            color="black"
            position="fixed"  // 改为固定定位
            right="4"
            top="4"
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            h="100vh"
          >
            {previewImage && (
              <Image
                src={previewImage}
                alt="Full Preview"
                maxH="90vh"
                objectFit="contain"
                borderRadius="md"
              />
            )}
          </Box>
        </ModalContent>
      </Modal>

    </>
  );

};

export default InlineEditBox;