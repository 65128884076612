import React, { useState, useEffect } from 'react';
import BaseMemberActivityPageComponent from '../../components/base/BaseMemberActivityPageComponent'
import AddEditActivityModal from './AddEditActivityModal';
import NewsCard from './NewsCard';

const NewsPage = () => {
  const [language, setLanguage] = useState('en'); 
  const tableName = 'activity';
  const primaryKey = 'id';
  const [isModalOpen, setModalOpen] = useState(false);
  const [initialData, setInitialData] = useState(null);

  const keyMappings = {
    id: 'ID',
    title: 'Title',
    image: 'Image',
    game: 'Game',
    draw_attempts: 'Draw Attempts',
    play_type: 'Play Type',
    prize: 'Prize',
    publish_period: 'Publish Period',
    event_start: 'Event Start Time',
    event_period: 'Event Period',
    status: 'Status',
  };

  const tableHeaders = [
    'id',
    'title',
    'image',
    'game', 
    'draw_attempts',
    'play_type',
    'prize',
    'publish_period',
    'event_period',
    'status',
  ];

  return (
    <BaseMemberActivityPageComponent
      txt="Activity List"
      addItemComponent={AddEditActivityModal}
      itemCardComponent={NewsCard}
      showToggleView={false}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} 
      showPagination={true}
      showAddIcon={true}
      isViewDisabled={false}
      primaryKey={primaryKey}
      isMultiLanguageSupported ={true} 
      hideDelete = {true}
    />
  );
};

export default NewsPage;

