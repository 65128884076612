// FormFields/ImageField.jsx
import React, { useState, useEffect, useCallback, useRef, forwardRef } from 'react';
import { FormControl, FormLabel, Input, Image, Box, Button, Text, useColorModeValue, IconButton, Spinner } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { MdPhoto } from 'react-icons/md';
import { blacken } from '@chakra-ui/theme-tools';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';

const ImageField = ({ label, value, onChange, onPreview, tableName, onDelete, fileInputRef, showPreview = true, language, isMultiLanguageSupported, ...rest }) => {
  const [uploading, setUploading] = useState(false);
  const [isImageError, setIsImageError] = useState(false);
  const iconColor = useColorModeValue('gray.300', 'gray.300');

  useEffect(() => {
    if (value) {
      setIsImageError(false);
    }
  }, [value]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true); // 开始上传
    try {
      const folder = tableName === 'icon_setting' ? 'icon' : 
                     tableName === 'tutorial_settings' ? 'tutorial' : 'icon';
                  
      const publicUrl = await global.genericapi.uploadImageAndGetPublicUrl(folder, file);
      if (publicUrl) {
        onChange(publicUrl);
        setIsImageError(false);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setUploading(false); // 上传完成
    }
  };

  const handleClick = () => {
    if (isMultiLanguageSupported) {
      if (fileInputRef.current && fileInputRef.current[language]) {
        fileInputRef.current[language].click();
      } else {
        console.error(`fileInputRef 的 language ${language} 未定义`);
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      } else {
        console.error('fileInputRef 未定义');
      }
    }
  };

  return (
    <FormControl mb={4}>
      <FormLabel>{label}</FormLabel>
      {uploading ? ( // 显示上传指示器
        <Box display="flex" alignItems="center" justifyContent="center" width={160} height={160} border="1px" borderStyle="dashed" borderColor="gray.300" p={1}>
          <Spinner size="lg" />
        </Box>
      ) : value && !isImageError ? (
        <Box>
          {showPreview && value && (
            <Box position="relative" display="inline-block">
              <Image 
                src={value} 
                boxSize="150px" 
                objectFit="contain" 
                mt={2} 
                onClick={() => onPreview?.(value)}
                onError={() => setIsImageError(true)} 
              />
              <IconButton
                icon={<DeleteIcon />}
                onClick={onDelete}
                position="absolute"
                top="3px"
                right="3px"
                size="sm"
                aria-label="Delete Image"
                color="gray.500"
                bg="gray.200"
                borderRadius="full"
                opacity="0.8"
                _hover={{ bg: "gray.300", opacity: 1 }}
                p="4px"
                _active={{ bg: "gray.400" }}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width={160} height={160} border="1px" borderStyle="dashed" borderColor="gray.300" p={1} onClick={handleClick}>
          <Box color={iconColor}>
            <Icon as={MdPhoto} boxSize={30} color={iconColor} />
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.500" cursor="pointer">
              Click to upload an image
            </Text>
          </Box>
        </Box>
      )}
      <Input
        ref={(ref) => {
          if (isMultiLanguageSupported) {
            fileInputRef.current[language] = ref;
          } else {
            fileInputRef.current = ref;
          }
        }}
        type="file"
        onChange={handleImageChange}
        style={{ display: 'none' }} // 隐藏 input
        {...rest}
      />
    </FormControl>
  );
};

export default ImageField;
