import { PrizeInputField } from 'views/admin/components/base/FormFields';
import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditNewsModal = ({ isOpen, onClose, initialData, onSave,isEdit,primaryKey}) => {
  if (!initialData) {
    console.error("Initial data is null or undefined");
    return null; 
  }
 
  const dataStructure = {
   name : { type: 'text', label: 'Prize Name', required: true },
   type : { type: 'select', label: 'Prize Type', required: false, options: [
    { value: 'Digital Reward', label: 'Digital Reward' },
    { value: 'Physical Item', label: 'Physical Item' },
    { value: 'Others', label: 'Others' }
   ] },
   amount : { type: 'number', label: 'Prize Amount', required: true },
   probability : { type: 'number', label: 'Prize Probability', required: true },
   image : { type: 'image', label: 'Prize Image', required: true },
   detail : { type: 'textarea', label: 'Detail', required: false },
  };

  return (
    <BaseAddEditModal
      isEdit={isEdit}
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      primaryKey={initialData.activityId}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="activity_prize"
      isMultiLanguageSupported={true} 
    />
  );
};

export default AddEditNewsModal;








