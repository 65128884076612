// FormFields/NumberField.jsx
import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';

const NumberField = ({ label, value, onChange, ...rest }) => {
  const handleChange = (e) => {
    const rawValue = e.target.value;
    
    // 处理空值或无效输入
    if (rawValue === '' || rawValue === '-') {
      onChange(null); // 传递 null 表示无值
      return;
    }

    const numValue = rawValue
    if (!isNaN(numValue)) {
      onChange(numValue);
    }
  };
  const handleWheel = (e) => {
    e.target.blur(); // 失去焦点防止滚轮触发
  };
  return (
    <FormControl mb={4}>
      <FormLabel>{label}</FormLabel>
      <Input
        type="number"
        value={value ?? ''} // 空值显示为空白
        onChange={handleChange}
        onWheel={handleWheel} // 禁用滚轮调整
        {...rest}
      />
    </FormControl>
  );
};

export default NumberField;