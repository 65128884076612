// TableOperations.js

const defaultOperations = {
  add: async (tableName, data) => {
    return await global.genericapi.addRecord(tableName, data);
  },
  update: async (tableName, id, data, mainKey) => {
    return await global.genericapi.updateRecord(tableName, id, data, mainKey);
  },
  delete: async (tableName, id, mainKey = 'id') => {
    return await global.genericapi.deleteRecord(tableName, id, mainKey);
  },
  get: async (tableName) => {
    return await global.genericapi.fetchRecords(tableName);
  }
  // await global.genericapi.deleteRecord(tableName, item[primaryKey], primaryKey);
};

const TableOperations = {
  force_update_history: {
    add: (data) => global.genericapi.updateForceRecord(data),
    update: (data) => global.genericapi.updateForceRecord(data),
  },
  coupons: {
    add: (data) => global.genericapi.rpcRecord('create_coupon', data),
    update: (data) => global.genericapi.rpcRecord('update_coupon', data),
  },
  latest_news: {
    add: (data) => global.genericapi.rpcRecord('create_latest_news', data),
    // update: (data) => global.genericapi.rpcRecord('update_latest_news', data),
  },
  main_banner: {
    query: (data) => global.genericapi.rpcRecord('get_main_banners', data),
    add: (data) => global.genericapi.rpcRecord('create_main_banner', data),
    update: (data) => global.genericapi.rpcRecord('update_main_banner', data),
    delete: (data) => global.genericapi.rpcRecord('delete_main_banner', data),
  },
  home_banner: {
    query: (data) => global.genericapi.rpcRecord('get_home_banners', data),
    add: (data) => global.genericapi.rpcRecord('create_home_banner', data),
    update: (data) => global.genericapi.rpcRecord('update_home_banner', data),
    delete: (data) => global.genericapi.rpcRecord('delete_home_banner', data),
  },
  tutorial_settings: {
    query: (data) => global.genericapi.rpcRecord('get_tutorials', data),
    add: (data) => global.genericapi.rpcRecord('create_tutorial', data),
    update: (data) => global.genericapi.rpcRecord('update_tutorial', data),
    delete: (data) => global.genericapi.rpcRecord('delete_tutorial', data),
  },
  cms_user: {
    // query: (data) => global.genericapi.rpcRecord('get_tutorials', data),
    add: (data) => global.genericapi.invoke('cms_user_registration', data),
    // update: (data) => global.genericapi.rpcRecord('update_tutorial', data),
    delete: (data) => global.genericapi.invoke('cms_user_deletion', data),
  },
  stores: {
    // query: (data) => global.genericapi.rpcRecord('get_stores', data),
    add: (data) => global.genericapi.rpcRecord('create_store', data),
    update: (data) => global.genericapi.rpcRecord('update_store', data),
    delete: (data) => global.genericapi.rpcRecord('delete_store', data),
  },
  events_translation: {
    query: (data) => global.genericapi.rpcRecord('get_events', data),
    add: (data) => global.genericapi.rpcRecord('create_events', data),
    update: (data) => global.genericapi.rpcRecord('update_events', data),
    delete: (data) => global.genericapi.rpcRecord('delete_events', data),
  },
  event_registration:{
     query: (data) => global.genericapi.rpcRecord('get_event_registrations', data),
  },
  users_profile:{
    delete: (data) => global.genericapi.rpcRecord('delete_users_profile', data),
    query: (data) => global.genericapi.rpcRecord('get_users_profile', data),
    update:(data) => global.genericapi.rpcRecord('update_member_points', data),
  },
  game:{
    delete: (data) => global.genericapi.rpcRecord('delete_game', data),
    query: (data) => global.genericapi.rpcRecord('get_game', data),
  },
  activity:{
    delete: (data) => global.genericapi.rpcRecord('delete_activity', data),
    query: (data) => global.genericapi.rpcRecord('get_activity', data),
    add:(data) => global.genericapi.rpcRecord('create_activity', data),
  },
  activity_translations:{
    add:(data) => global.genericapi.rpcRecord('create_activity', data),
    update:(data) => global.genericapi.rpcRecord('update_activity', data),
  },
  activity_prize:{
    query:(data) => global.genericapi.rpcRecord('get_activity_prize', data),
    add:(data) => global.genericapi.rpcRecord('create_activity_prize', data),
    update:(data) => global.genericapi.rpcRecord('update_activity_prize', data),
    delete:(data) => global.genericapi.rpcRecord('delete_activity_prize', data),
  },
  game_select:{
    query:(data) => global.genericapi.rpcRecord('get_game_select', data),
  },
  game:{
    add:(data) => global.genericapi.rpcRecord('create_game', data),
    query:(data) => global.genericapi.rpcRecord('get_game', data),
    update:(data) => global.genericapi.rpcRecord('update_game', data),
    delete:(data) => global.genericapi.rpcRecord('delete_game', data),
  },
  game_record:{
    query:(data) => global.genericapi.rpcRecord('get_game_record', data),
  },
 
  // Add more tables as needed

  // Default operations
  default: defaultOperations
};

export default TableOperations;

