import React, { useState, useEffect } from 'react';
import BasePageComponent from '../../components/base/BasePageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';
import { useLocation } from "react-router-dom"
import InlineEditBox from '../../components/base/InlineEditBox';
import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import TableOperations from '../../components/base/utils/TableOperations';
import {
  Box,
  Text,
  Card,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

const EventDetailPage = (isOpen) => {
  const [language, setLanguage] = useState('en');
  const [primaryKey,setPrimaryKey] = useState("id");
  const [data,setData] = useState(null);
  const location = useLocation();
  const { item } = location.state || {};
  const tableName = 'events_translation';
  const tableName2 = 'event_registrations';


  if (!item || !item.id) {
    console.error("No item found in location state or item.id is undefined");
    return null; 
  }
  console.log(item, 'Item from location state');
  localStorage.setItem('eventId', item.parentId);

  const keyMappings = {
    member_id: 'Member ID',
    registered_date: 'registered date',
    registered_code: 'registered code',
    status: 'Status',
  };

  const tableHeaders = [
    'member_id',
    'registered_date',
    'registered_code',
    'status',
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "8px" }}>
      <Box
        p="20px"
        borderWidth="1px"
        borderRadius="lg"
        mb="20px"
        boxShadow="md"
        position="relative"
      >
        <InlineEditBox
          isOpen={true}
          initialData={item}
          onSave={(updatedData) => console.log("Saved data:", updatedData)}
          const dataStructure = {{
            name: { type: 'text', label: 'Name', required: true },
            location: {
              type: 'text',
              label: 'Location',
              required: true
            },
            image_url: { type: 'image', label: 'Image', required: true },
            registration_start:{
              type: 'dateTime',
              label: 'Registration Start',
              required: true
            },
            registration_end:{
              type: 'dateTime',
              label: 'Registration End',
              required: true
            },
            event_date:{
              type: 'date',
              label: 'Event Date',
              required: true
            },
            publish_start:{
              type: 'dateTime',
              label: 'Publish Start',
              required: true
            },
            publish_end:{
              type: 'dateTime',
              label: 'Publish End',
              required: true
            },
            detail:{
              type: 'rich-text',
              label: 'Detail',
              required: true
            },
          }}
          isEdit={true}
          isMultiLanguageSupported={true}
          tableName={tableName}
        />
      </Box>
      <Box
        p="20px"
        borderWidth="1px"
        borderRadius="lg"
        mb="20px"
        boxShadow="md"
        position="relative"
      >
      
    <BasePageComponent
      txt="Event Registration"
      addItemComponent={AddEditNewsModal}
      itemCardComponent={NewsCard}
      showToggleView={false}
      tableName='event_registration'
      tableHeaders={tableHeaders}
      keyMappings={keyMappings}
      showPagination={true}
      showSearch={true}
      showAddIcon={false}
      searchPlaceholder="Search by status"
      primaryKey={primaryKey}
      searchFields="status"
    />
      </Box>
    </Box>
  );
};

export default EventDetailPage;

