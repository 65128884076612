import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditNewsModal = ({ isOpen, onClose, initialData, onSave,isEdit }) => {
 
  const dataStructure = {
      name: { type: 'text', label: 'Name', required: true },
      location: {
        type: 'text',
        label: 'Location',
        required: true
      },
      image_url: { type: 'image', label: 'Image', required: false },
      registration_start:{
        type: 'dateTime',
        label: 'Registration Start',
        required: true
      },
      registration_end:{
        type: 'dateTime',
        label: 'Registration End',
        required: true
      },
      event_date:{
        type: 'date',
        label: 'Event Date',
        required: true
      },
      publish_start:{
        type: 'dateTime',
        label: 'Publish Start',
        required: true
      },
      publish_end:{
        type: 'dateTime',
        label: 'Publish End',
        required: true
      },
      detail:{
        type: 'rich-text',
        label: 'Detail',
        required: true
      },
  };

  const defaultData = {
    name: '',
    image_url: null,
    registration_start: '',
    registration_end: '',
    event_date: '',
    location: '',
    detail: '',
    publish_start: '',
    publish_end: '',
  };
  return (
    <BaseAddEditModal
      isEdit={isEdit}
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData ? { ...initialData } : defaultData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="events_translation"
      isMultiLanguageSupported = {true}
    />
  );
};

export default AddEditNewsModal;








