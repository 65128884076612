import React, { useState } from 'react';
import BaseMemberActivityPageComponent from '../../components/base/BaseMemberActivityPageComponent';
import AddEditProtocolModal from './AddEditProtocolModal';
import ProtocolCard from './ProtocolCard';

const ProtocolListPage = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [initialData, setInitialData] = useState(null);

    const keyMappings = {
        protocol: 'Protocol',
    };

    const tableHeaders = ['protocol'];

    return (
        <BaseMemberActivityPageComponent
            txt="Protocol List"
            addItemComponent={AddEditProtocolModal}
            itemCardComponent={ProtocolCard}
            showToggleView={false}
            tableName="protocol"
            tableHeaders={tableHeaders}
            keyMappings={keyMappings}
            showPagination={true}
            showAddIcon={true}
            isViewDisabled={false}
        />
    );
};

export default ProtocolListPage; 