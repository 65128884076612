// components/PageControls.js
// import React from 'react';
// import { Flex, InputGroup, Input, InputRightElement, IconButton, Button, Select } from '@chakra-ui/react';
// import { SearchIcon, RepeatIcon, AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

// const PageControls = ({
//   searchTerm,
//   onSearchChange,
//   filter,
//   onFilterChange,
//   filterFields,
//   sortField,
//   onSortChange,
//   sortFields,
//   sortOrder,
//   onSortOrderChange,
//   isGridView,
//   onViewToggle,
//   onRefresh,
//   onAdd,
//   searchPlaceholder,
// }) => {
//   return (
//     <Flex justifyContent="space-between" mb={4}>
//       <Flex>
//         <InputGroup mr={4}>
//           <Input
//             placeholder={searchPlaceholder}
//             value={searchTerm}
//             onChange={onSearchChange}
//           />
//           <InputRightElement children={<SearchIcon />} />
//         </InputGroup>
//         <Select
//           placeholder="Filter by"
//           value={filter}
//           onChange={onFilterChange}
//           mr={4}
//         >
//           {filterFields.map((field) => (
//             <option key={field.value} value={field.value}>
//               {field.label}
//             </option>
//           ))}
//         </Select>
//         <Select
//           placeholder="Sort by"
//           value={sortField}
//           onChange={onSortChange}
//           mr={2}
//         >
//           {sortFields.map((field) => (
//             <option key={field.value} value={field.value}>
//               {field.label}
//             </option>
//           ))}
//         </Select>
//         <Button onClick={onSortOrderChange}>
//           {sortOrder === 'asc' ? <ViewIcon /> : <ViewOffIcon />}
//         </Button>
//       </Flex>
//       <Flex>
//         <IconButton icon={<RepeatIcon />} onClick={onRefresh} mr={2} />
//         <IconButton icon={isGridView ? <ViewIcon /> : <ViewOffIcon />} onClick={onViewToggle} mr={2} />
//         <Button onClick={onAdd} leftIcon={<AddIcon />}>
//           Add
//         </Button>
//       </Flex>
//     </Flex>
//   );
// };

// export default PageControls;


// components/PageControls.js
import React from 'react';
import { Flex, InputGroup, Input, InputRightElement, IconButton, Button, Select, HStack, Spacer, Box, Text, Heading, Divider, VStack } from '@chakra-ui/react';
import { SearchIcon, CloseIcon, RepeatIcon, AddIcon, ViewIcon, ViewOffIcon, ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FaPlus, FaEdit, FaTrash, FaThList, FaTh, FaSync } from 'react-icons/fa';

const PageControl = ({
  txt,
  searchQuery,
  showSearch,
  onSearchChange,
  onSearchClear,
  onSearchValueChange,
  filter,
  onFilterChange,
  filterFields,
  sortField,
  onSortChange,
  sortFields,
  sortOrder,
  onSortOrderChange,
  isGridView,
  onToggleView,
  onRefresh,
  onAddClick,
  searchPlaceholder,
  showToggleView,
  showAddIcon,
  showFilter
}) => {
  return (
    <Box p={0} mb={6}>
      <VStack spacing={6} align="start" w="100%" bg='white'>
        <HStack justifyContent="space-between" w="100%" >
          <HStack spacing={4} mt={[4, 0]} wrap="wrap">
            {showSearch && (
              <InputGroup key='Search' minW="300px" maxW="600px">
                <Input
                  placeholder={searchPlaceholder}
                  value={searchQuery}
                  onChange={onSearchChange}
                  borderRadius="full"
                />
                <InputRightElement width={'3rem'}>
                  {searchQuery ? (
                    <IconButton
                      icon={<CloseIcon />}
                      onClick={onSearchClear}
                      size="sm"
                      mr={1}
                      borderRadius="full"
                    />
                  ) : (
                    <IconButton
                      icon={<SearchIcon />}
                      onClick={onSearchValueChange}
                      size="sm"
                      mr={1}
                      borderRadius="full"
                    />
                  )}
                </InputRightElement>
              </InputGroup>
            )}
          </HStack>
          {/* <HStack spacing={4} mt={[4, 0]} wrap="wrap">
            {showFilter && (
              <Select
              placeholder="Filter by"
              value={filter}
              onChange={onFilterChange}
              mr={4}
            >
              {filterFields.map((field) => (
                <option key={field.value} value={field.value}>
                  {field.label}
                </option>
              ))}
            </Select>
            )}
          </HStack> */}
          <HStack spacing={4} mt={[4, 0]} wrap="wrap">
            <IconButton variant='action' ml="auto" icon={<RepeatIcon />} aria-label="Refresh" onClick={onRefresh} />
            {onAddClick && showAddIcon && (
              <IconButton
                variant='action' ml="auto"
                icon={<AddIcon />}
                aria-label="Add"
                onClick={onAddClick}
              />
            )}
            {onToggleView && showToggleView && (
              <IconButton
                variant='action' ml="auto"
                icon={isGridView ? <FaThList /> : <FaTh />}
                aria-label="Toggle View"
                onClick={onToggleView}
              />
            )}
          </HStack>
        </HStack>
        {/* <Flex wrap="wrap" width='100%'>
          <Spacer></Spacer>
          {onFilterChange && (
            <Select
              key="Filter by"
              minW={200}
              maxW={260}
              placeholder="Filter by"
              value={filter}
              onChange={onFilterChange}
              mr={4}
            >
              {filterFields.map((field) => (
                <option key={field.value} value={field.value}>
                  {field.label}
                </option>
              ))}
            </Select>
          )}
          {onSortChange && (
            <Select
              key="Sort by"
              minW={200}
              maxW={260}
              placeholder="Sort by"
              value={sortField}
              onChange={onSortChange}
              mr={2}
            >
              {sortFields.map((field) => (
                <option key={field.value} value={field.value}>
                  {field.label}
                </option>
              ))}
            </Select>
          )}
          {onSortOrderChange && (
            <Button onClick={onSortOrderChange}>
              {sortOrder === 'asc' ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </Button>
          )}
        </Flex> */}
        
      </VStack>
    </Box>

  );
};

export default PageControl;


