import React, { useState } from 'react';
import BaseMemberActivityPageComponent from '../../components/base/BaseMemberActivityPageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';

const NewsPage = () => {
  const [language, setLanguage] = useState('en'); 
  const tableName = 'game';
  const primaryKey = 'id';

  const keyMappings = {
    name: 'Name',
    image: 'Image',
    display_images: 'Display',
    game_play_url: 'Game Play',
  };

  const tableHeaders = [
    'name',
    'image',
    'display_images',
    'game_play_url'
  ];

  return (
    <BaseMemberActivityPageComponent
      txt="Game List"
      addItemComponent={AddEditNewsModal}
      itemCardComponent={NewsCard}
      showToggleView={false}
      isMultiLanguageSupported = {true}
      tableName={tableName}
      tableHeaders={tableHeaders}
      keyMappings={keyMappings} 
      showPagination={true}
      showAddIcon={true}
      isViewDisabled={false}
      primaryKey={primaryKey}
      hideView={true}
      hideDelete={true}
    />
  );
};

export default NewsPage;

