import React, { useState, useEffect } from 'react';
import BaseMemberActivityPageComponent from '../../components/base/BaseMemberActivityPageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';
import { useLocation } from "react-router-dom"
import InlineEditBox from '../../components/base/InlineEditBox';
import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import TableOperations from '../../components/base/utils/TableOperations';
import {
  Box,
  Text,
  Card,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

const NewsPage = (isOpen) => {
  const [language, setLanguage] = useState('en');
  const [primaryKey,setPrimaryKey] = useState("id");
  const [data,setData] = useState(null);
  const location = useLocation();
  const { item } = location.state || {};
  const tableName = 'activity_prize';
  const tableName2 = 'game_record';

  const [gameOptions, setGameOptions] = useState([]);

  // 获取游戏选项
  const fetchGameOptions = async () => {
    try {
      const options = await TableOperations['game_select'].query()
      const formattedOptions = options.map(game => ({
        value: game.game_id, 
        label: game.name 
      }));
      setGameOptions(formattedOptions);
  
    } catch (error) {
      console.error("Error fetching game options:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchGameOptions(); 
    }
  }, [isOpen]);
  if (!item || !item.activityId) {
    console.error("No item found in location state or item.id is undefined");
    return null; 
  }
  console.log(item, 'Item from location state');
  localStorage.setItem('itemId', item.activityId);


  const keyMappings2 = {
    id: 'Id',
    identifier: 'Identifier',
    member_id: 'Member Id',
    prize_name: 'Lottery Results',
    created_at: 'Created At',
  };

  const tableHeaders2 = [
    'id',
    'identifier',
    'member_id',
    'prize_name',
    'created_at',
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "8px" }}>
      <Box
        p="20px"
        borderWidth="1px"
        borderRadius="lg"
        mb="20px"
        boxShadow="md"
        position="relative"
      >
        <Text fontSize="xl" fontWeight="bold" ml={2}  mb={3}>Game Record</Text>
        <BaseMemberActivityPageComponent
          itemCardComponent={NewsCard}
          showToggleView={false}
          tableName={tableName2}
          tableHeaders={tableHeaders2}
          keyMappings={keyMappings2}
          showPagination={true}
          showSearch={true}
          searchPlaceholder="Search by ID/Member ID"
          searchFields="email"
          showAddIcon={false}
          primaryKey={primaryKey}
          showActions={false}
        />
      </Box>
    </Box>

  );
};

export default NewsPage;

