import React, { useRef, useState } from 'react'; 
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
  IconButton,
  Box,
  Button,
  Image,
  Select,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import ImageField from './ImageField';
import SelectField from './SelectField';
const PrizeInputField = ({ prize = [], onChange, onRemove, onAdd,tableName }) => {
  const [previewImage, setPreviewImage] = useState(null)
const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const handleInputChange = (index, field, value) => {
    onChange(index, field, value);
  };
  const typeOptions = [
    { value: 'code', label: 'Code' },
    { value: 'email', label: 'Email' }
  ];
  const fileInputRefs = useRef(new Map()); 

  // 处理图片上传
  const handleImageUpload = (index, url) => {
    handleInputChange(index, 'image', url);
  };

  // 处理图片删除
  const handleImageDelete = (index) => {
    // 添加空值检查
    if (!prize || index >= prize.length) return;

    // 清除文件引用
    const inputRef = fileInputRefs.current.get(index);
    if (inputRef) inputRef.value = null;
    
    // 触发父组件更新
    onChange(index, 'image', '');
  };

  // 修改 onAdd 函数以确保新奖品包含 type 字段
  const handleAddPrize = () => {
    const newPrize = {
      name: '',
      type: 'code', // 默认类型
      amount: '',
      probability: '',
      detail: '',
      image: '',
    };
    onAdd(newPrize);
  };

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
      {isPreviewOpen && (
  <Modal isOpen={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} size="xl">
    <ModalOverlay />
    <ModalContent>
      <ModalCloseButton />
      <ModalBody>
        <Image src={previewImage} alt="Preview" w="100%" objectFit="contain" />
      </ModalBody>
    </ModalContent>
  </Modal>
)}
        <Stack direction="row" spacing={4} justifyContent="left">
          <Button
            leftIcon={<AddIcon />}
            colorScheme="teal"
            onClick={handleAddPrize} // 更新为新的添加函数
          >
            Add Prize
          </Button>
        </Stack>
      </Box>
      {prize?.map((prizeItem, index) => (
        <Box borderWidth="1px" borderRadius="md" p={4} mb={4} key={index}>
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Prize Name</FormLabel>
              <Input
                value={prizeItem.name || ''}
                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                placeholder="Enter prize name"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Prize Type</FormLabel>
              <Select
                value={prizeItem.type || 'code'} // 确保使用 type 字段
                onChange={(e) => handleInputChange(index, 'type', e.target.value)}
              >
                {typeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            {/* 图片上传 */}
            <ImageField
              label="Prize Image"
              value={prizeItem.image || ''}
              onChange={(url) => handleImageUpload(index, url)}
              onDelete={() => handleImageDelete(index)}
              onPreview={() => {
                setPreviewImage(prizeItem.image)
                setIsPreviewOpen(true)
              }}
              tableName={tableName || 'default'} // 传递tableName参数
              fileInputRef={(ref) => {
                if (ref) {
                  fileInputRefs.current.set(index, ref);
                } else {
                  fileInputRefs.current.delete(index);
                }
              }}
            />

            <FormControl isRequired>
              <FormLabel>Amount</FormLabel>
              <NumberInput
                min={0}
                value={prizeItem.amount}
                onChange={(valueString) => handleInputChange(index, 'amount', valueString)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Probability</FormLabel>
              <NumberInput
                min={0}
                max={100}
                step={0.1}
                precision={2}
                value={prizeItem.probability}
                onChange={(valueString) => handleInputChange(index, 'probability', valueString)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Prize Details </FormLabel>
              <Textarea
                value={prizeItem.detail || ''}
                onChange={(e) => handleInputChange(index, 'detail', e.target.value)}
                placeholder="Enter prize details"
                rows={3}
              />
            </FormControl>
            <Stack direction="row" spacing={4} justifyContent="right">
              <IconButton
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={() => onRemove(index)}
                aria-label="Remove Prize"
              />
            </Stack>
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

export default PrizeInputField; 