import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import React from 'react';

const AddEditNewsModal = ({ isOpen, onClose, initialData, onSave,isEdit }) => {
  console.log("Initial Data:", initialData)
  const displayImages = initialData && Array.isArray(initialData.display_images) ? initialData.display_images : [];
  const dataStructure = {
    
      name: { type: 'text', label: 'Name', required: true },
      image: { type: 'image', label: 'Image', required: true },
      display_images: { type: 'MultiImageField', label: 'Display', maxCount: 5, required: true},
      game_play_url: { type: 'text', label: 'Game Play', required: false },
    
  };

  return (
    <BaseAddEditModal
      isEdit={isEdit}
      isOpen={isOpen}
      onClose={onClose}
      initialData={initialData}
      onSave={onSave}
      dataStructure={dataStructure}
      tableName="game"
      isMultiLanguageSupported={true} 
    />
  );
};

export default AddEditNewsModal;








