import React, { useRef } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Image, Switch, HStack, Tooltip, Flex, useColorModeValue, Text } from '@chakra-ui/react';
import { EditIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons';
const formatDate = (value) => {

  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;

};

const CustomMemberTable = ({
  data,
  tableHeaders,
  handleView,
  handleEdit,
  handleDelete,
  handleToggleActive,
  keyMappings = {}, // Key mappings for display names
  isViewDisabled,
  isEditDisabled, // New prop
  isDeleteDisabled,
  currentPage, // Accept currentPage as a prop
  itemsPerPage, // Accept
  showActions = true,
  handleSort, // 接收 handleSort
  hideView,
  hideEdit,
  hideDelete,
  tableName
}) => {
  const tableRef = useRef(null);
  const tableSize = 'md';


  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const theadBgColor = useColorModeValue('gray.50', 'gray.700');
  const thHoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getColumnWidth = (index) => {
    if (index === 0) return '10%';
    const remainingWidth = 90;
    const totalColumns = tableHeaders.length + (showActions ? 1 : 0);
    return `${remainingWidth / totalColumns}%`;
  };

  const renderTh = (key) => {
    const keys = key.split('.');
    var name = (keyMappings[key] || key)
    if (keys.length > 1) {
      const last_key = keys[keys.length - 1]
      var name = (keyMappings[last_key] || last_key)
    }
    return capitalizeFirstLetter(name);

  };

  const renderCellValue = (value) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return JSON.stringify(value);
    }
    return value;
  };

  const renderImage = (imageSrc) => (
    <Box maxWidth="100px" height="100px" display="flex" alignItems="center" justifyContent="center">
      <Image
        src={imageSrc?.replace(/['"\\]/g, '')}
        alt="Display Image"
        objectFit="contain"
        maxHeight="100%"
        maxWidth="100%"
      />
    </Box>
  );
  const renderImageStack = (images) => {
    // 最多显示3张
    const displayImages = images.slice(0, 3); 
    const total = images.length;
    
    return (
      <Box 
        position="relative" 
        width="100px" 
        height="100px"
        overflow="visible"
      >
        {displayImages.map((image, index) => {
          const imageUrl = typeof image === 'string' ? image : image?.url;
          
          // 计算堆叠位置
          const stackStyle = {
            position: 'absolute',
            left: `${index * 10}%`, // 每张向右偏移10%
            zIndex: index + 1,
            width: "90%", // 保留10%可见区域
            height: "100%",
            boxShadow: "0 0 2px rgba(0,0,0,0.1)",
            border: "1px solid white"
          };
  
          return (
            <Box key={index} {...stackStyle}>
              <Image
                src={imageUrl?.replace(/['"\\]/g, '')}
                alt={`Image ${index + 1}`}
                objectFit="cover"
                width="100%"
                height="100%"
                borderRadius="sm"
              />
            </Box>
          );
        })}
  
        {/* 超过3张显示计数 */}
        {total > 3 && (
          <Box
            position="absolute"
            right="0"
            bottom="0"
            zIndex={4}
            bg="rgba(0,0,0,0.6)"
            color="white"
            px={2}
            py={1}
            fontSize="xs"
            borderRadius="md"
          >
            +{total - 3}
          </Box>
        )}
      </Box>
    );
  };
  const renderCellKey = (key, item) => {
    const keys = key.split('.');
    var key_name = keys
    let value = item[key_name];
    if (keys.length === 3) {
      let indexTable = keys[0]
      if (item[indexTable].length > 0) {
        let index = keys[1]
        let indexKey = keys[2]
        value = item[indexTable][index][indexKey]
      }
    } else if (keys.length === 2) {
      let indexTable = keys[0]
      let indexKey = keys[1]
      value = item[indexTable][indexKey]
    }

    if (value === null || value === undefined) return '';
    
    if (key === 'display_images') {
      if (!Array.isArray(value) || value.length === 0) {
        return 'No Images';
      }
      
      const validImages = value.filter(item => 
        typeof item === 'string' || 
        (typeof item === 'object' && item?.url)
      );
      
      if (validImages.length === 0) return 'Invalid Images';
      
      return renderImageStack(validImages);
    }

    if (/image_url|image/i.test(key)) {

      const imageUrl = typeof value === 'string' ? value : '';
      const cleanImageUrl = imageUrl.replace(/['"\\]/g, '');
      return (
        <Box maxWidth="100px" height="100px" display="flex" alignItems="center" justifyContent="center">
          <Image
            src={cleanImageUrl}
            alt="Activity"
            objectFit="contain"
            maxHeight="100%"
            maxWidth="100%"
          />
        </Box>
      );
    } else if (/card_face/i.test(key)) {
      return (
        <Box maxWidth="80px" mt={3}>
          <Image src={value} objectFit="contain" alt=" " />
        </Box>
      );

    } else if (key === 'is_active') {
      return (
        <Switch
          isChecked={item[key]}
          onChange={() => handleToggleActive(item)}
          size="sm"
        />
      )
    } else if (['created_at', 'updated_at', 'sent_at', 'scheduled_at'].indexOf(key_name) > -1) {
      return (renderCellValue(formatDate(value)))
    } else if (key_name.includes('date')) {
      return (renderCellValue(formatDate(value)))
    } else if (key_name.includes('tier')) {
      value = value?.charAt(0).toUpperCase() + value?.slice(1)
      return (renderCellValue(value))
    } else if (key_name.includes('language')) {
      if (value === 'zh-Hant') {
        value = 'Traditional Chinese'
      } else if (value === 'zh-Hans') {
        value = 'Simplified Chinese'
      } else {
        value = 'English'
      }
      return (renderCellValue(value))
    } else if (key_name.includes('platform')) {
      if (value === 'ios') {
        value = 'iOS'
      } else if (value === 'android') {
        value = 'Android'
      }
      return (renderCellValue(value))
    } else if (key_name.includes('_color')) {
      return <Box height="20px" width="20px" bg={value} borderRadius="sm" />;
    } else {
      return (renderCellValue(value))
    }

  };

  

  const renderCellContent = (key, item) => {
 
    const value = renderCellKey(key, item);
    if (key === 'detail') {
      const minWidth = value.length <= 10 ? '80px' : '120px';
      const maxWidth = '200px';
      return (
        <Box
          minWidth={minWidth}
          maxWidth={maxWidth}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="normal"
          display="-webkit-box"
          style={{
            WebkitLineClamp: 4,
            WebkitBoxOrient: 'vertical',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </Box>
      );
    }
    if (typeof value === 'string') {
      const minWidth = value.length <= 10 ? '80px' : '120px';
      const maxWidth = '200px';

      const content = (
        <Box
          minWidth={minWidth}
          maxWidth={maxWidth}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {value}
        </Box>
      );

      if (value.length > 10) {
        return (
          <Tooltip label={value}>
            <Box
              maxWidth="300px"
              minWidth="120px"
              maxHeight="200px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="normal"
              display="-webkit-box"
              style={{
                WebkitLineClamp: 4,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      }

      return content;
    }

    return value;
  };

  return (
    <Box borderWidth="1px" borderRadius="md" boxShadow="lg" overflow="hidden" overflowX="auto"
      overflowY="auto"
      maxW="100%"
      maxH="900px">
      <Table
        width="100%"
        ref={tableRef}
        variant="simple"
        size={tableSize}
        bg={bgColor}
        color={textColor}
        borderRadius="md"
        minWidth="800px"
      >
        <Thead>
          <Tr>
            <Th
              textTransform="none"
              textAlign="center"
              bg={theadBgColor}
              _hover={{ bg: thHoverBgColor }}
              fontSize="md"
            >
              #
            </Th>
            {tableHeaders.map((key, index) => (
              <Th
                key={key}
                textTransform="none"
                textAlign="left"
                bg={theadBgColor}
                cursor="pointer"
                onClick={() => handleSort(key)}
                _hover={{ bg: thHoverBgColor }}
                width={getColumnWidth(index)}
                py={7}
                fontSize="md"
              >
                <Flex align="center">
                  <Text whiteSpace="nowrap">{keyMappings[key] || capitalizeFirstLetter(key)}</Text>
                </Flex>
              </Th>
            ))}
            {showActions && (
              <Th
                textTransform="none"
                textAlign="center"
                bg={theadBgColor}
                _hover={{ bg: thHoverBgColor }}
                py={7}
                fontSize="md"
              >
                <Flex align="center" justify="center">
                  <Text whiteSpace="nowrap">Actions</Text>
                </Flex>
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, rowIndex) => (
            <Tr key={rowIndex} _hover={{ bg: hoverBgColor }}>
              <Td textAlign="center">{(currentPage - 1) * itemsPerPage + rowIndex + 1}</Td>
              {tableHeaders.map((key, colIndex) => (
                <Td key={key} textAlign="left" width={getColumnWidth(colIndex)}>
                  {renderCellContent(key, item)}
                </Td>
              ))}
              {showActions && (
                <Td textAlign="center">
                  <HStack justify="center" spacing={2}>
                    {!hideView && (
                      <IconButton
                        onClick={() => handleView(item, tableName)}
                        icon={<ViewIcon />}
                        colorScheme="purple"
                        aria-label="View"
                        size="sm"
                        isDisabled={isViewDisabled(item)}
                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                      />
                    )}
                    {!hideEdit && (
                      <IconButton
                        onClick={() => handleEdit(item)}
                        icon={<EditIcon />}
                        colorScheme="blue"
                        aria-label="Edit"
                        size="sm"
                        isDisabled={isEditDisabled(item)}
                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                      />
                    )}
                    {!hideDelete && (
                      <IconButton
                        onClick={() => handleDelete(item)}
                        icon={<DeleteIcon />}
                        colorScheme="red"
                        aria-label="Delete"
                        size="sm"
                        isDisabled={isDeleteDisabled(item)}
                        _disabled={{ opacity: 0.4, cursor: 'not-allowed' }}
                      />
                    )}
                  </HStack>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CustomMemberTable;
