import React, { useState, useEffect } from 'react';
import BaseMemberActivityPageComponent from '../../components/base/BaseMemberActivityPageComponent'
import AddEditNewsModal from './AddEditNewsModal';
import NewsCard from './NewsCard';
import { useLocation } from "react-router-dom"
import InlineEditBox from '../../components/base/InlineEditBox';
import BaseAddEditModal from '../../components/base/BaseAddEditModal';
import TableOperations from '../../components/base/utils/TableOperations';
import {
  Box,
  Text,
  Card,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

const NewsPage = (isOpen) => {
  const [language, setLanguage] = useState('en');
  const [primaryKey,setPrimaryKey] = useState("id");
  const [data,setData] = useState(null);
  const location = useLocation();
  const { item } = location.state || {};
  const tableName = 'activity_prize';
  const tableName2 = 'game_record';

  const [gameOptions, setGameOptions] = useState([]);

  // 获取游戏选项
  const fetchGameOptions = async () => {
    try {
      const options = await TableOperations['game_select'].query()
      const formattedOptions = options.map(game => ({
        value: game.game_id, 
        label: game.name 
      }));
      setGameOptions(formattedOptions);
  
    } catch (error) {
      console.error("Error fetching game options:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchGameOptions(); 
    }
  }, [isOpen]);

  if (!item || !item.activityId) {
    console.error("No item found in location state or item.id is undefined");
    return null; 
  }
  console.log(item, 'Item from location state');
  localStorage.setItem('itemId', item.activityId);

  const keyMappings = {
    type: 'Type',
    name: 'Name',
    image: 'Image',
    probability: 'Probability',
    amount: 'Amount',
    detail: 'Detail',
  };

  const tableHeaders = [
    'type',
    'name',
    'probability',
    'amount',
    'image',
    'detail',
  ];
  const keyMappings2 = {
    id: 'Id',
    identifier: 'Identifier',
    member_id: 'Member Id',
    prize_name: 'Lottery Results',
    created_at: 'Created At',
  };

  const tableHeaders2 = [
    'id',
    'identifier',
    'member_id',
    'prize_name',
    'created_at',
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "8px" }}>
      <Box
        p="20px"
        borderWidth="1px"
        borderRadius="lg"
        mb="20px"
        boxShadow="md"
        position="relative"
      >
        <InlineEditBox
          isOpen={true}
          initialData={item}
          onSave={(updatedData) => console.log("Saved data:", updatedData)}
          dataStructure={{
            title: { type: 'text', label: 'Title', required: true },
            image: { type: 'image', label: 'Image', required: true },
            game_id: { type: 'select', label: 'Game', required: false, options: gameOptions },
            callback_url: { type: 'text', label: 'Callback URL', required: false },
            protocol_url: { type: 'text', label: 'Protocol URL', required: false },
            draw_attempts: { type: 'number', label: 'Draw Attempts', required: true },
            publish_start: {
              type: 'dateTime',
              label: 'Publish Start',
              required: true
            },
            publish_end: {
              type: 'dateTime',
              label: 'Publish End',
              required: true
            },
            event_start: {
              type: 'dateTime',
              label: 'Event Start',
              required: true
            },
            event_end: {
              type: 'dateTime',
              label: 'Event End',
              required: true
            },
            play_type: {
              type: "select",
              label: 'Play Type',
              required: false,
              options: [
                { value: 'draw_code', label: 'Draw Code' },
                { value: 'email', label: 'Email' },
                { value: 'phone', label: 'Phone' },
              ]
            },
            status: {
              type: "select",
              label: 'Status',
              required: false,
              options: [
                { value: 'draft', label: 'Draft' },
                { value: 'active', label: 'Active' },
                { value: 'in_active', label: 'Inactive' },
                { value: 'completed', label: 'Completed' },
              ]
            },
            detail: { type: 'textarea', label: 'Detail', required: false },
          }}
          isEdit={true}
          isMultiLanguageSupported={true}
          tableName = "activity_translations"
        />
      </Box>
      <Box
        p="20px"
        borderWidth="1px"
        borderRadius="lg"
        mb="20px"
        boxShadow="md"
        position="relative"
      >
        <Text fontSize="xl" fontWeight="bold" ml={2} mb={-10}>Prize</Text>
        <BaseMemberActivityPageComponent
          txt="Prize Info"
          addItemComponent={AddEditNewsModal}
          itemCardComponent={NewsCard}
          showToggleView={false}
          tableName={tableName}
          tableHeaders={tableHeaders}
          keyMappings={keyMappings}
          showPagination={true}
          showAddIcon={true}
          primaryKey={primaryKey}
          hideView={true}
          hideDelete={true}
          isMultiLanguageSupported={true}
        />
        <AddEditNewsModal
        initialData={data} 
        primaryKey={primaryKey}
        />
      </Box>
    </Box>

  );
};

export default NewsPage;

